import React, { PureComponent } from 'react';
import { CardBody } from 'reactstrap';
import SavedSearchesList from './components/SavedSearchesList';

class SavedSearchesTable extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            tl: {},
        };
    }

    componentDidMount = async () => {
        let tl = {}
        if (this.props.location && this.props.location.state && this.props.location.state.tl) {
            tl = this.props.location.state.tl
        } else {
            tl = await JSON.parse(localStorage.getItem('langJson'));
        }
        await this.setState({
            tl: tl
        })
    }

    render() {
        const { tl } = this.state;
        return (
            <CardBody className='p-0'>
                <div className='text-center pt-2 pageHeadingCss'>
                    {'Saved Searches'}
                </div>
                <SavedSearchesList tl={tl} />
            </CardBody>
        );
    }
}

export default SavedSearchesTable

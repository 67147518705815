import React, { PureComponent } from 'react';

// Social Login Handling Modules
import FbLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';

// Redux
import { reduxForm } from 'redux-form';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { connect } from 'react-redux';
import validate from '../../Validations/validate';

// UI components
import Loader from '../../App/Loader';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

// Config &  socket
import fetchMethodRequest from '../../../config/service';
import Config from '../../../config/config';
import socket from '../../../config/Socket';

// Facebook colored svg icon
const fbIcon = (
  <svg width="100" height="40" viewBox="0 0 100 40">
    <g fill="none" fillRule="evenodd">
      <rect width="99" height="39" x=".5" y=".5" fill="#FFF" stroke="#B4B4B4" rx="19.5"></rect>
      <path
        fill="#3A589B"
        d="M46.527 32.96h5.058V20.477h3.374l.447-4.3h-3.821l.005-2.154c0-1.12.105-1.723 1.683-1.723h2.109V8h-3.375c-4.054 0-5.48 2.087-5.48 5.595v2.583H44v4.301h2.527v12.48z"
      >
      </path>
    </g>
  </svg>
)

// Google colored svg icon
const googleIcon = (
  <svg width="100" height="40" viewBox="0 0 100 40">
    <g fill="none" fillRule="evenodd">
      <rect width="99" height="39" x=".5" y=".5" fill="#FFF" stroke="#B4B4B4" rx="19.5"></rect>
      <g fillRule="nonzero">
        <path
          fill="#FBBB00"
          d="M44.432 22.086l-.696 2.6-2.544.053A9.955 9.955 0 0 1 40 20a9.95 9.95 0 0 1 1.118-4.599l2.266.415.992 2.252a5.944 5.944 0 0 0-.32 1.932c0 .734.132 1.437.376 2.086z"
        >
        </path>
        <path
          fill="#518EF8"
          d="M59.825 18.132a10.019 10.019 0 0 1-.044 3.956 9.998 9.998 0 0 1-3.52 5.71h-.001l-2.853-.146-.404-2.52a5.96 5.96 0 0 0 2.564-3.044H50.22v-3.956h9.605z"
        >
        </path>
        <path
          fill="#28B446"
          d="M56.26 27.798A9.958 9.958 0 0 1 50 30a9.998 9.998 0 0 1-8.808-5.261l3.24-2.653a5.946 5.946 0 0 0 8.57 3.045l3.258 2.667z"
        >
        </path>
        <path
          fill="#F14336"
          d="M56.383 12.302l-3.24 2.652a5.948 5.948 0 0 0-8.767 3.114l-3.257-2.667A9.998 9.998 0 0 1 50 10c2.426 0 4.651.864 6.383 2.302z"
        >
        </path>
      </g>
    </g>
  </svg>
)

class LogInForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }


  // Sign in Handler using social cred
  sendSocialRespToServer = (values) => {
    let userBody = {
      email: values.email,
      displayName: values.name,
      entityType: "customer",
    };
    localStorage.setItem('loginBody', JSON.stringify(userBody));

    return fetchMethodRequest('POST', 'customers', userBody)
      .then(async (res) => {
        if (res && res.respCode && res.respCode === 200) {
          await this.setState({
            isLoading: true
          });
          let userDetails = res.details;
          if (userDetails && userDetails._id) {
            if (res.accessToken) {
              let tokenInfo = {
                accessToken: res.accessToken,
                refreshToken: res.refreshToken,
                tokenExpires: res.tokenExpires,
              };
              userDetails = { ...userDetails, ...tokenInfo };
              // Save user credentials in storage
              localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
              localStorage.setItem('favProperties', JSON.stringify(userDetails.favProperties))
            }
            socket.emit('userConnected', { userId: userDetails._id });
          }
          await this.setState({
            isLoading: false
          });
          await this.props.onLoginSuccess(userDetails);
          showToasterMessage(res.respMessage, 'success');
        } else if (res && res.errorMessage) {
          setTimeout(() => this.setState({
            isLoading: false
          }), 2500);
          showToasterMessage(res.errorMessage, 'error');
          return true;
        }
      }).catch((err) => {
        return err;
      });
  }

  // Facebook Sign in Handler
  faceBookLoginResponseHandler = async (res) => {
    if (res && res.name && res.email) {
      await this.sendSocialRespToServer(res);
    } else {
      // showToasterMessage('Login failed', 'error')
    }
  }

  // Google Sign in Handler
  googleLoginResponseHandler = async (res) => {
    if (res && res.profileObj && res.profileObj.email && res.profileObj.name) {
      await this.sendSocialRespToServer(res.profileObj);
    } else {
      // showToasterMessage('Login failed', 'error')
    }
  }

  render() {
    const { tl } = this.props;
    return (
      <div className='socialLoginsDiv'>
        <Loader loader={this.state.isLoading} />
        <div className='socialLoginTitle1'>
          <h5 className=''>
            {tl['connectWith'] ? tl['connectWith'] : 'Connect with'}
          </h5>
        </div>
        <GoogleLogin
          clientId={Config.googleLoginClientId}
          render={renderProps => (
            <span
              className='socialIconSpan'
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              {googleIcon}
            </span>
          )}
          onSuccess={this.googleLoginResponseHandler}
          onFailure={this.googleLoginResponseHandler}
        />
        <FbLogin
          appId={Config.fbLoginAppId}
          fields="name,email,picture"
          callback={this.faceBookLoginResponseHandler}
          render={renderProps => (
            <span
              className='socialIconSpan'
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              {fbIcon}
            </span>
          )}
        />
      </div>
    );
  }
}

LogInForm = reduxForm({
  form: 'log_in_form', // a unique identifier for this form
  validate,
  enableReinitialize: true,
})(LogInForm);

// You have to connect() to any reducers that you wish to connect to yourself
LogInForm = connect(
  state => ({
    initialValues: state.commonData.data // pull initial values from account reducer
  }),
  { load: loadAccount } // bind account loading action creator
)(LogInForm);

export default LogInForm;
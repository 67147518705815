import React from 'react';
import { Col, Container, Card } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

// Input Components
import Select from 'react-select';

// Icons
import SearchIcon from 'mdi-react/SearchIcon';

// Modals
import SessionExpireModal from '../SessionExpire/sessionExpireModal';

// Config
import fetchMethodRequest from '../../config/service';
import Loader from '../App/Loader';

// Handle Input data on Search Click
const handleSearchSubmitClick = (state) => {
    let city = state.city && state.city.value ? state.city.value : 'Hyderabad';
    let locality = state.locality && state.locality.value ? state.locality.value : '';
    let propertyFor = state.propertyFor;
    if (propertyFor === 'sale') {
        propertyFor = 'Sale';
    }
    if (propertyFor === 'rent') {
        propertyFor = 'Rent';
    }
    if (city && propertyFor) {
        let myClientCriteria = {
            propertyFor: propertyFor,
            location: locality,
            city: city,
        }
        let myFilterCriteria = {
            direction: 'desc',
            limit: 12,
            page: 1,
            sortfield: 'created',
            criteria: []
        }
        if (propertyFor) {
            myFilterCriteria.criteria.push(
                { key: "propertyFor", value: propertyFor, type: "eq" }
            )
        }
        if (city) {
            myFilterCriteria.criteria.push(
                { key: "city", value: city, type: "eq" }
            )
        }
        if (locality) {
            myFilterCriteria.criteria.push(
                { key: "locality", value: locality, type: "eq" }
            )
        }
        let fromScreen = 'home'
        localStorage.setItem('myClientCriteria', JSON.stringify(myClientCriteria))
        localStorage.setItem('myFilterCriteria', JSON.stringify(myFilterCriteria))
        localStorage.setItem('fromScreen', JSON.stringify(fromScreen))
    }
    return true
}

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tl: {},
            isOpenSessionExpiredModal: false,
            isLoading: false,
            propertyFor: 'sale',
            city: { label: 'Hyderabad', value: 'Hyderabad' },
            cityOptions: [],
            localityOptions: [],
        };
    }

    // Custom Search Button for Select Component
    DropdownIndicator = () => {
        return (
            <Link
                className='localitySearchIndicator'
                to={{
                    pathname: `/search_properties`,
                    state: {
                        handle: handleSearchSubmitClick(this.state),
                        tl: this.state.tl,
                    },
                }}
            >
                <SearchIcon /><span>Search</span>
            </Link>
        );
    };

    // Get/Change Lang JSON
    changeLanguage = async (type) => {
        let lang;
        if (type && type === 'cons') {
            let lan = (localStorage.getItem('lang') && localStorage.getItem('lang') !== 'undefined' ? localStorage.getItem('lang') : null);
            if (lan && lan) {
                lang = lan;
            } else {
                lang = 'en'
            }
        } else if (type) {
            lang = type;
        } else {
            lang = 'en'
        }
        let url = `auth/languages?language=${lang}`;
        await fetchMethodRequest('GET', url).then(async resp => {
            if (resp) {
                await localStorage.setItem('lang', lang);
                await localStorage.setItem('langJson', JSON.stringify(resp));
                await this.setState({ tl: resp, lang: lang })
            }
        }).catch(err => {
            return err;
        })
    }

    componentDidMount = async () => {
        await this.setState({ isLoading: true });

        // Clearing un-necessary local values 
        localStorage.removeItem('myPropsSelected')
        localStorage.removeItem('searchPropsSelected')
        localStorage.removeItem('matchedPropsSelected')

        localStorage.removeItem('myIsGlobalChecked')
        localStorage.removeItem('searchIsGlobalChecked')
        localStorage.removeItem('matchedIsGlobalChecked')

        localStorage.removeItem('myPropsDeselected');
        localStorage.removeItem('searchPropsDeselected');
        localStorage.removeItem('matchedPropsDeselected');

        let tl = {}
        if (this.props.location && this.props.location.state && this.props.location.state.tl) {
            tl = await this.props.location.state.tl
        } else if (localStorage.getItem('langJson') && localStorage.getItem('langJson') !== 'undefined') {
            tl = await JSON.parse(localStorage.getItem('langJson'));
        } else {
            await this.changeLanguage(null)
        }
        await this.getCityOptions();
        await this.getLocationOptions();
        await this.setState({
            tl: tl,
            isLoading: false,
        })
    }

    // Get Available Cities List
    getCityOptions = async () => {
        fetchMethodRequest('GET', 'locations').then(async resp => {
            if (resp && resp.respCode === 200) {
                let states = resp.states;
                let cities = [];
                const uniqueCities = [...new Set(states.map(item => item.city))];
                uniqueCities.map(item => {
                    cities.push(
                        { label: item, value: item }
                    )
                    return cities;
                })
                this.setState({
                    cityOptions: cities,
                    city: cities && cities.length > 0 ? cities[1] : '',
                })
            }
        })
    }

    // Get Available Localities of selected City
    getLocationOptions = async (val) => {
        let url = 'locations';
        let apiUrl = '';
        let city = val ? val.value : this.state.city && this.state.city.value ? this.state.city.value : null;
        let filterCriteria = {};
        filterCriteria['criteria'] = [];
        if (city) {
            filterCriteria.criteria.push(
                {
                    key: 'city',
                    value: city,
                    type: 'eq'
                }
            )
        }
        let filterString = JSON.stringify(filterCriteria);
        apiUrl = `${url}?filter=${filterString}`;
        fetchMethodRequest('GET', apiUrl).then(async resp => {
            if (resp && resp.respCode === 200) {
                let states = resp.states;
                let localities = [];
                const uniqueLocalities = [...new Set(states.map(item => item.locality))];
                uniqueLocalities.map(item => {
                    localities.push(
                        { label: item, value: item }
                    )
                    return localities;
                })
                await this.setState({
                    localityOptions: localities,
                    propertySearchCity: city,
                })
            }
        })
    }

    // Dropdown option selection Handler
    handleChange = async (name, val) => {
        await this.setState({
            [`${name}`]: val,
        })
        if (name && name === 'city') {
            await this.getLocationOptions(val);
            await this.localitySelectionRef.onChange(null)
        }
    }

    // Locality Input Handler
    handleInputChange = async (val) => {
        let valLength = val.length;
        await this.setState({ inputLength: valLength })
    }

    // Property for Tab onClick/onChange Handler
    handlePropertyForTabClick = (val) => {
        this.setState({
            propertyFor: val
        })
    }

    render() {
        const { tl, cityOptions, localityOptions, city } = this.state;
        return (
            <Container className='landingBg' >
                <Loader loader={this.state.isLoading} />
                <Col md={12} className='text-center'>
                    <h1 className='landingHeadingCss'>
                        {tl['propertiesFor'] ? tl['propertiesFor'] : 'Properties for'}{' '}
                        <span>
                            {tl[this.state.propertyFor] ? tl[this.state.propertyFor] : this.state.propertyFor}{' '}</span>
                        {tl['in'] ? tl['in'] : 'in'}{' '}
                        <span>{this.state.propertySearchCity ? this.state.propertySearchCity : 'Hyderabad'}</span>
                    </h1>
                </Col>
                <Col md={12} className='px-1'>
                    <Card className='landingSearchCard'>
                        <div className='d-flex mb-3'>
                            <div
                                className={this.state.propertyFor === 'sale' ? 'activeSearchTab' : 'searchTab'}
                                onClick={() => this.handlePropertyForTabClick('sale')}
                            >
                                {tl['sale'] ? tl['sale'] : 'Sale'}
                            </div>
                            <div
                                className={this.state.propertyFor === 'rent' ? 'activeSearchTab ml-5' : 'searchTab ml-5'}
                                onClick={() => this.handlePropertyForTabClick('rent')}
                            >
                                {tl['rent'] ? tl['rent'] : 'Rent'}
                            </div>
                        </div>
                        <div className='row mx-0 justify-content-center'>
                            <div className='col-4 col-md-3 px-0'>
                                <Select
                                    ref={(ref) => (this.citySelectionRef = ref)}
                                    id='city'
                                    className='col-12 px-0 citySelection'
                                    name={'city'}
                                    options={cityOptions}
                                    components={{ IndicatorSeparator: () => null }}
                                    placeholder={tl['selectCity'] ? tl['selectCity'] : 'City...'}
                                    maxHeight={120}
                                    onChange={(e) => this.handleChange('city', e)}
                                    defaultValue={city}
                                />
                            </div>
                            <div className="col-6 col-md-7 px-0">
                                <Select
                                    ref={(ref) => (this.localitySelectionRef = ref)}
                                    id='locality'
                                    className='col-12 px-0 locationSelection'
                                    name={'locality'}
                                    options={localityOptions}
                                    components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                                    placeholder={tl['localitySearchPlaceholder'] ? tl['localitySearchPlaceholder'] : 'Search for locality'}
                                    maxHeight={120}
                                    onChange={(e) => this.handleChange('locality', e)}
                                    openMenuOnClick={false}
                                    isClearable={true}
                                    openMenuOnFocus={false}
                                />
                            </div>

                            <Link
                                className='col-2 col-md-2 px-0 localitySearchIndicator'
                                to={{
                                    pathname: `/search_properties`,
                                    state: {
                                        handle: handleSearchSubmitClick(this.state),
                                        tl: this.state.tl,
                                    },
                                }}
                            >
                                <div className=''>
                                    <SearchIcon /><span>{tl['search'] ? tl['search'] : 'Search'}</span>
                                </div>
                            </Link>
                        </div>
                    </Card>
                </Col>
                {/* #Session Expire Modal */}
                {
                    this.state.isOpenSessionExpiredModal ?
                        <SessionExpireModal
                            isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                            tl={tl}
                        />
                        : null
                }
            </Container >
        );
    }
}

export default withRouter(Dashboard);
/* eslint indent: "off" */
import React from 'react';
import { Route, Switch } from 'react-router-dom';

//Page Layout
import MainWrapper from './MainWrapper';
import Layout from '../Layout/index';

// Account Related
import ChangePassword from '../Account/ChangePassword';
import ForgotPassword from '../Account/ForgotPassword';
import ResetPassword from '../Account/ResetPassword';
import Profile from '../Account/Profile';
import Settings from '../Settings/settings';

// Landing_Login
import Login from '../LogIn';
import Landing from '../Landing/userLandingPage';

// DashBoard
import Dashboard from '../Dashboard/index';

//Registration
import Registration from '../Registration';

// Agent Screens
import MyProperties from '../Properties/components/commonProperties';
import AddProperty from '../Properties/components/addProperty';
import PropertyDetails from '../Properties/components/PropertyDetails';

// Error : Page Not Found
import ErrorNotFound from './404'
import MatchedPropsList from '../MatchedProperties/index';
import SavedSearchesList from '../SavedSearches/index';

const wrappedRoutes = () => {
  return <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/my_properties" component={MyProperties} />
      <Route path="/search_properties" exact component={MyProperties} />
      <Route path="/search_properties/:sharedCriteriaId" component={MyProperties} />
      <Route path="/matched_properties" component={MyProperties} />
      <Route path="/favourite_properties" component={MyProperties} />

      <Route path="/add_property" component={AddProperty} />
      <Route path="/properties/:id" component={AddProperty} />
      <Route path="/propertyDetails/:id" component={PropertyDetails} />
      <Route path="/profile" component={Profile} />
      <Route path="/changepassword" component={ChangePassword} />
      <Route path="/settings" component={Settings} />
      <Route path="/matchedPropertiesList" component={MatchedPropsList} />
      <Route path="/savedSearchesList" component={SavedSearchesList} />
      <Route path="/dashboard" component={Dashboard} />
      <Route exact path="/" component={Landing} />
    </div>
  </div>
}

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <Route path="/changerecoverpassword/:encodedEmailId/:active" component={ResetPassword} />
        <Route path="/changerecoverpassword/:encodedEmailId" component={ResetPassword} />
        <Route path="/register" component={Registration} />
        <Route path="/forgot_password" component={ForgotPassword} />
        <Route path="/log_in" component={Login} />
        <Route path="/" component={wrappedRoutes} />
        <Route path="*" component={ErrorNotFound} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;

import React from 'react';
import { Button } from 'reactstrap';

import TranslationTool from '../Translation/TranslationTool';

// Fields
import DefaultInput from '../../shared/components/form/DefaultInput';
import RenderFileInputField from '../Form/components/FileUpload';

// Redux
import { Field, reduxForm } from 'redux-form';

import validate from '../Validations/validate';
import { connect } from 'react-redux';
import { load as loadAccount } from '../../redux/reducers/commonReducer';

import FavIcon from 'mdi-react/HeartOutlineIcon';
import SavedSearchIcon from 'mdi-react/EmailCheckOutlineIcon';
import EditIcon from 'mdi-react/PencilIcon';

import fetchMethodRequest from '../../config/service';
import Loader from '../App/Loader';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';

import SessionExpireModal from '../SessionExpire/sessionExpireModal';
import configImages from '../../config/configImages';
import config from '../../config/config';
import configMessages from '../../config/configMessages';

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField)

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            tl: {},
            lang: (localStorage.getItem('lang') && localStorage.getItem('lang') !== 'undefined' ? localStorage.getItem('lang') : null),
            user: JSON.parse(localStorage.getItem('loginCredentials')),
        };
    }

    componentDidMount = async () => {
        localStorage.removeItem('myPropsSelected')
        localStorage.removeItem('searchPropsSelected')
        localStorage.removeItem('matchedPropsSelected')

        localStorage.removeItem('myIsGlobalChecked')
        localStorage.removeItem('searchIsGlobalChecked')
        localStorage.removeItem('matchedIsGlobalChecked')

        localStorage.removeItem('myPropsDeselected');
        localStorage.removeItem('searchPropsDeselected');
        localStorage.removeItem('matchedPropsDeselected');

        let tl = {};
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        if (this.props.location && this.props.location.state && this.props.location.state.tl) {
            tl = this.props.location.state.tl;
        } else {
            tl = JSON.stringify(localStorage.getItem('langJson'))
        }
        await this.setState({
            tl: tl,
            user: user,
            userId: user._id,
        })
        if (this.props.load) {
            this.props.load(user)
        }
    }

    // Lang onChange Handler
    changeLanguage = async (lang) => {
        let url = `auth/languages?language=${lang}`;
        fetchMethodRequest('GET', url).then(async resp => {
            if (resp && resp.respCode) {
                await localStorage.setItem('lang', lang);
                await localStorage.setItem('langJson', JSON.stringify(resp));
                await this.setState({ tl: resp, lang: lang });
                await window.location.reload();
            } else {
                if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    this.setState({ isLoading: false });
                    showToasterMessage(resp.errorMessage, 'error');
                }
            }
        }).catch(err => {
            return err;
        })
        if (this.props.location && this.props.location.state && this.props.location.state.changeLanguage) {
            this.props.location.state.changeLanguage(lang);
        } else {
            return null
        }
    }

    // get data from server based on Id
    getUserData = async () => {
        if (localStorage.getItem('loginCredentials')) {
            let user = JSON.parse(localStorage.getItem('loginCredentials'));
            this.setState({
                userId: user._id,
            });
            let Url = 'customers/' + user._id;
            fetchMethodRequest('GET', Url).then(async (response) => {
                if (response && response.respCode) {
                    user.photo = response.details.photo;
                    await localStorage.setItem('loginCredentials', JSON.stringify(user))
                    await this.setState({
                        user: response.details,
                    });
                    await this.setState({
                        isLoading: false
                    });
                } else if (response && response.errorCode) {
                    this.setState({
                        isLoading: false
                    });
                }
            }).catch((err) => {
                return err;
            });
            if (this.props.load) {
                this.props.load(user)
            }
        } else {
            return;
        }
    }

    // Profile Photo Upload Handler
    uploadProfilePic = async (picture) => {
        let url = `uploads?uploadPath=customer&entityType=customer&_id=${this.state.userId}`
        await this.setState({ isLoading: true });
        if (picture) {
            fetchMethodRequest('POST', url, picture, 'upload').then(async (res) => {
                if (res && res.respCode) {
                    await this.getUserData(res.respMessage);
                    await showToasterMessage(res.respMessage, 'success');
                } else {
                    if (res.errorMessage && res.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                        })
                    } else {
                        showToasterMessage(res.errorCode, 'error');
                        await this.setState({ isLoading: false });
                    }
                }
            }).catch((err) => {
                return err;
            });
        } else {
            await this.setState({ isLoading: false });
        }
    }

    // Handle submit button
    submitPhone = async (values) => {
        let phoneNumber = values.phoneNumber;
        let user = await JSON.parse(localStorage.getItem('loginCredentials'));
        let body = {
            email: user.email,
            phoneNumber: phoneNumber.length === 10 ? '91' + phoneNumber : phoneNumber,
            entityType: 'customer'
        }
        if (user && user.email && phoneNumber) {
            fetchMethodRequest('POST', 'auth/sendOtp', body).then(async resp => {
                if (resp && resp.respCode) {
                    showToasterMessage(resp.respMessage, 'success');
                    await this.setState({
                        isSmsSent: true,
                        phoneNumber: phoneNumber
                    })
                    this.props.reset()
                } else {
                    if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                            phoneNumber: phoneNumber
                        })
                    } else {
                        this.setState({
                            isLoading: false,
                            phoneNumber: phoneNumber
                        });
                        showToasterMessage(resp.errorMessage, 'error');
                    }
                }
            }).catch(err => {
                return err
            })
        }
    }

    submitOtp = async (values) => {
        let user = await JSON.parse(localStorage.getItem('loginCredentials'));
        let body = {
            email: user.email,
            otp: values.otp1,
            entityType: 'customer',
            phoneNumber: this.state.phoneNumber.length === 10 ? '91' + this.state.phoneNumber : this.state.phoneNumber,
        }
        if (user && user.email && values.otp) {
            fetchMethodRequest('POST', 'auth/verifyOtp', body).then(async resp => {
                if (resp && resp.respCode) {
                    showToasterMessage(resp.respMessage, 'success');
                    await this.setState({
                        isSmsSent: false,
                    })
                    user.isOtpVerified = true;
                    user.phoneNumber = this.state.phoneNumber;
                    if (this.props.load) {
                        this.props.load(user);
                    }
                    await localStorage.setItem('loginCredentials', JSON.stringify(user))

                } else {
                    if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                        })
                    } else {
                        this.setState({ isLoading: false });
                        showToasterMessage(resp.errorMessage, 'error');
                    }
                }
            })
        }
    }

    resendOtp = async () => {
        let body = { phoneNumber: this.state.phoneNumber }
        await this.submitPhone(body);
    }

    handleChange = otp => this.setState({ otp });


    render() {
        const { tl, lang, user, isSmsSent } = this.state;
        const { handleSubmit } = this.props;
        return (
            <div className='p-3'>
                <Loader loader={this.state.isLoading} />
                <div className='row justify-content-start px-md-5 px-sm-3 mx-0'>
                    <div className="col-sm-5 col-md-5 col-lg-3 profileStaticCol">
                        <div className='ud_staticProfDiv'>
                            <div className='ud_photo'>
                                <img
                                    src={configImages.profileUserBg}
                                    alt="bg"
                                    className='ud_photo_bg'
                                />
                                <img
                                    src={user.photo ? `${config.imgUrl}customer/${user.photo}` : configImages.defaultImg}
                                    alt="user"
                                    className='ud_photo_user'
                                />
                            </div>
                            <div className='ud_name'>
                                {user.displayName}
                            </div>
                            <div className='ud_email'>
                                {user.email}
                            </div>
                        </div>

                        <div className='ud_staticNavDiv mt-5'>
                            <a href="/favourite_properties">
                                <FavIcon />
                                <span>
                                    {tl['favourites'] ? tl['favourites'] : 'Favourites'}
                                </span>
                            </a>
                            <a href="/savedSearchesList">
                                <SavedSearchIcon />
                                <span>
                                    {tl['savedSearches'] ? tl['savedSearches'] : 'Saved Searches'}
                                </span>
                            </a>
                            <hr />
                            <p className='active m-0'>
                                <EditIcon />
                                <span>
                                    {tl['editProfile'] ? tl['editProfile'] : 'Edit Profile'}
                                </span>
                            </p>
                        </div>
                    </div>

                    <div className="col-sm-7 col-md-7 col-lg-6 ml-lg-4 px-sm-3 px-0">
                        <div className='ud_edit_div row m-0'>
                            <div className='ud_contact_photo col-md-auto px-0'>
                                <img
                                    src={user.photo ? `${config.imgUrl}customer/${user.photo}` : configImages.defaultImg}
                                    alt="user"
                                />
                            </div>
                            <div className='ud_contactForm col-md pr-0'>

                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        {tl['name'] ? tl['name'] : 'Name'}
                                    </span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name='displayName'
                                            component={DefaultInput}
                                            type="name"
                                            isDisable={true}
                                        />
                                    </div>
                                </div>
                                <div className="form__form-group">
                                    <span className="form__form-group-label">
                                        {tl['email'] ? tl['email'] : 'Email'}
                                    </span>
                                    <div className="form__form-group-field">
                                        <Field
                                            name='email'
                                            component={DefaultInput}
                                            type="email"
                                            isDisable={true}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="form__form-group-field col-auto ud_uploadPhotoButton px-0">
                                <Field
                                    ref={(r) => this.profilePhotoLink = r}
                                    name="photo"
                                    className='profileUpload'
                                    component={RenderFileInputField}
                                    onRef={(ref) => (this.profileUploadRef = ref)}
                                    acceptType="image/*"
                                    chooseLabel={tl['changePhoto'] ? tl['changePhoto'] : 'Change Photo'}
                                    type='profile'
                                    mode='basic'
                                    savePhoto={this.uploadProfilePic}
                                />
                            </div>
                        </div>

                        {/* Phone Number Update */}
                        <div className='ud_edit_div'>
                            <div className="ud_chnagePhoneTitle">
                                {tl['changePhoneNumber'] ? tl['changePhoneNumber'] : 'CHANGE PHONE NUMBER'}
                            </div>
                            <div className='ud_contactForm'>
                                <form
                                    className="form"
                                    onSubmit={handleSubmit(isSmsSent ? this.submitOtp : this.submitPhone)}
                                >
                                    {isSmsSent ?
                                        <div className="form__form-group">
                                            <div className="form__form-group-field">
                                                <Field
                                                    name='otp1'
                                                    component={DefaultInput}
                                                    placeholder={tl['enterOtp'] ? tl['enterOtp'] : 'Please enter 6 digit OTP'}
                                                    validate={required}
                                                    maxLength={6}
                                                />
                                            </div>
                                            <div className="form__form-group-field redendOtpText">
                                                <span
                                                    onClick={this.resendOtp}
                                                >
                                                    {tl['resendOtp'] ? tl['resendOtp'] : 'Resend Otp'}
                                                </span>
                                            </div>
                                        </div>
                                        :
                                        <div className="form__form-group mt-3">
                                            <div className="form__form-group-field">
                                                <Field
                                                    name='phoneNumber'
                                                    component={DefaultInput}
                                                    type='phone'
                                                    validate={required}
                                                    placeholder={tl['phone'] ? tl['phone'] : 'Phone'}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <Button
                                        color='primary'
                                        type="submit"
                                        className='ud_phoneOtpButton'
                                    >
                                        {isSmsSent ?
                                            tl['verify'] ? tl['verify'] : 'Verify'
                                            : tl['sendOtp'] ? tl['sendOtp'] : 'Send OTP'
                                        }
                                    </Button>
                                </form>
                            </div>
                        </div>

                        {/* Language Change */}
                        <div className='ud_edit_div'>
                            <div className="d-flex">
                                <h5 className='mr-2'>
                                    {tl['changeLanguage'] ? tl['changeLanguage'] : 'Change Language'}
                                </h5>
                                <TranslationTool
                                    changeLanguage={this.changeLanguage}
                                    lang={lang}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* #Session Expire Modal */}
                {
                    this.state.isOpenSessionExpiredModal ?
                        <SessionExpireModal
                            isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                            tl={tl}
                        />
                        : null
                }
            </div >
        );
    }
}

Settings = reduxForm({
    form: 'Settings_contact_form', // a unique identifier for this form
    enableReinitialize: true,
    validate,
})(Settings);
Settings = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(Settings);
export default (Settings);
import { GET_LOGIN_DATA } from "../actions/loginAction";

const initialState = {
    loginUser: {}
};

function loginReducer(state = initialState, action) {
    if (action.type === GET_LOGIN_DATA) {
        return {
            loginUser: action.payload ? action.payload : state.loginUser
        };
    }
    return state;
}

export default loginReducer;
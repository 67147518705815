import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

import InfoIcon from 'mdi-react/InfoCircleIcon';
import PriceTagIcon from 'mdi-react/TagTextOutlineIcon';

import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

import SessionExpireModal from '../../SessionExpire/sessionExpireModal';
import Config from '../../../config/config';

class ClientSelectModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount = async () => {
    }

    // Handle save button
    submit = async (values) => {
        let user = await JSON.parse(localStorage.getItem('loginCredentials'));
        let clientCriteria = this.props.clientCriteria;
        let selectedProperties = this.props.properties ? this.props.properties : [];
        let selectedIds = [];


        if (clientCriteria.totalPriceMax) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.totalPriceMax;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.totalPriceMax;
            }

            clientCriteria['totalPriceMaxDigits'] = finalTempNumber
            clientCriteria['totalPriceMax'] = finalTempString
        }

        if (clientCriteria.totalPriceMin) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.totalPriceMin;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.totalPriceMin;
            }

            clientCriteria['totalPriceMinDigits'] = finalTempNumber
            clientCriteria['totalPriceMin'] = finalTempString
        }

        if (clientCriteria.builtUpAreaMax) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.builtUpAreaMax;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.builtUpAreaMax;
            }

            clientCriteria['builtUpAreaMaxDigits'] = finalTempNumber
            clientCriteria['builtUpAreaMax'] = finalTempString
        }

        if (clientCriteria.builtUpAreaMin) {
            let finalTempString = '';
            let finalTempNumber = '';
            let temp = clientCriteria.builtUpAreaMin;
            let tempNoComma = temp = temp.replace(/,/g, '');
            let tempInt = parseInt(tempNoComma);
            finalTempNumber = tempInt;

            if (tempInt >= 10000000) {
                finalTempString = (tempInt / 10000000).toFixed(2) + ' Crores';
            } else if (tempInt >= 100000) {
                finalTempString = (tempInt / 100000).toFixed(2) + ' Lakhs';
            } else {
                finalTempString = clientCriteria.builtUpAreaMin;
            }

            clientCriteria['builtUpAreaMinDigits'] = finalTempNumber
            clientCriteria['builtUpAreaMin'] = finalTempString
        }

        if (selectedProperties && selectedProperties.length > 0) {
            for (let i = 0; i < selectedProperties.length; i++) {
                selectedIds.push(selectedProperties[i]._id);
            }
        } else {
            selectedIds = [];
        }


        let body = {
            customerEmail: user.email,
            customerId: user._id,
            criteria: clientCriteria,
            result: selectedIds,
            search: { filter: this.props.saveSearchFilterObject }
        }
        if (user && user.email && user._id) {
            fetchMethodRequest('POST', 'criteria', body).then(async resp => {
                if (resp && resp.respCode) {
                    showToasterMessage(resp.respMessage, 'success')
                    await this.props.closeClientSelectModal('saved');
                } else {
                    if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                        })
                    } else {
                        this.setState({ isLoading: false });
                        showToasterMessage(resp.errorMessage, 'error');
                    }
                }
            })
        }
    }

    render() {
        const { tl, clientCriteria } = this.props;
        console.log('clientCriteria', clientCriteria)
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpenClientSelectModel}
                    className={`modal-dialog-centered modal-dialog--primary formModalBorderRadius saveSearchModal`}
                    toggle={() => this.props.closeClientSelectModal()}
                >
                    <Button
                        className='modalToggleButton'
                        color="primary"
                        close={true}
                        onClick={() => this.props.closeClientSelectModal()}
                    />
                    <ModalBody className='p-0'>
                        <div className='ss_header'>
                            <h4 className="text-center mb-3">
                                {tl['saveThisSearch'] ? tl['saveThisSearch'] : 'Save this search'}!
                            </h4>
                        </div>
                        <div className="ss_content_div">
                            <div className="ss_content_title">
                                {clientCriteria.propertyFor ? clientCriteria.propertyFor : 'Buy/Rent'}{' '}
                                {tl['properties'] ? tl['properties'] : 'Properties'}
                                {(clientCriteria.location || clientCriteria.city) ?
                                    (tl['in'] ? ` ${tl['in']} ` : ' in ') + (clientCriteria.location ? clientCriteria.location : clientCriteria.city)
                                    : null
                                }
                            </div>
                            {Object.keys(clientCriteria).map((item, index) => {
                                return (
                                    (clientCriteria[item] && (item !== 'propertyFor' && item !== 'city' && item !== 'location' && item !== 'builtUpAreaMax' && item !== 'builtUpAreaMin' && item !== 'totalPriceMax' && item !== 'totalPriceMin')) ?
                                        <div className="ss_content_data" key={index}>
                                            <InfoIcon
                                                className='mr-2'
                                            />
                                            {clientCriteria[item]}{' '}
                                            {item === 'bedRooms' ? 'BHK' : null}
                                            {item === 'facing' ? tl['facing'] ? tl['facing'] : 'Facing' : null}
                                        </div>
                                        : null
                                )
                            })}
                            {(clientCriteria['totalPriceMax'] || clientCriteria['totalPriceMin']) ?
                                <div className="ss_content_data">
                                    <PriceTagIcon
                                        className='mr-2'
                                    />
                                    {Config.currencySymbol}{' '}{clientCriteria['totalPriceMin'] ? clientCriteria['totalPriceMin'] : ''}{' - '}
                                    {Config.currencySymbol}{' '}{clientCriteria['totalPriceMax'] ? clientCriteria['totalPriceMax'] : ''}
                                </div>
                                : null
                            }
                            {(clientCriteria['builtUpAreaMax'] || clientCriteria['builtUpAreaMin']) ?
                                <div className="ss_content_data">
                                    <InfoIcon
                                        className='mr-2'
                                    />
                                    {clientCriteria['builtUpAreaMin'] ? clientCriteria['builtUpAreaMin'] : '0'}{' - '}
                                    {clientCriteria['builtUpAreaMax'] ? clientCriteria['builtUpAreaMax'] : ''}{' '}{Config.areaUnits}
                                </div>
                                : null
                            }
                        </div>
                        <div className="col-12 text-center px-0">
                            <Button
                                color='primary'
                                type="button"
                                className='mx-1 mb-0 mt-2'
                                onClick={this.submit}
                            >
                                {tl['save'] ? tl['save'] : 'Save'}
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>

                {/* #Session Expire Modal */}
                {this.state.isOpenSessionExpiredModal ?
                    <SessionExpireModal
                        isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                        tl={tl}
                    />
                    : null
                }
            </div>
        );
    }
}

export default ClientSelectModal
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import CountDisplay from './components/CountDisplay';
import fetch from '../../config/service';
import Loader from '../App/Loader';
import SessionExpireModal from '../SessionExpire/sessionExpireModal';
import showToasterMessage from '../UI/ToasterMessage/toasterMessage';

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dashboardData: {},
      isLoading: false,
      tl: {}
    };
  }

  componentDidMount = async () => {
    // Clearing un-necessary local values 
    localStorage.removeItem('myPropsSelected')
    localStorage.removeItem('searchPropsSelected')
    localStorage.removeItem('matchedPropsSelected')

    localStorage.removeItem('myIsGlobalChecked')
    localStorage.removeItem('searchIsGlobalChecked')
    localStorage.removeItem('matchedIsGlobalChecked')

    localStorage.removeItem('myPropsDeselected');
    localStorage.removeItem('searchPropsDeselected');
    localStorage.removeItem('matchedPropsDeselected');

    this.setState({ isLoading: true });
    let tl = {}
    if (this.props.location && this.props.location.state && this.props.location.state.tl) {
      tl = this.props.location.state.tl
    } else {
      tl = JSON.parse(localStorage.getItem('langJson'));
    }
    await this.setState({
      tl: tl,
    })
    let user = await JSON.parse(localStorage.getItem('loginCredentials'));
    await this.getCountData(user.email);
  }

  // Get Agent Stats 
  getCountData = async (email) => {
    this.setState({ isLoading: true });
    fetch('GET', `dashboard/getAgentCounts?email=${email}`)
      .then(async (response) => {
        if (response && response.respCode) {
          this.setState({ dashboardData: response, isLoading: false });
        } else {
          if (response.errorMessage && response.errorMessage === 'Session expired please login again.') {
            await this.setState({
              isOpenSessionExpiredModal: true,
            })
          } else {
            this.setState({ isLoading: false });
            showToasterMessage(response.errorMessage, 'error');
          }
        }
      }).catch((err) => {
        return err;
      });
  }

  render() {
    const { tl } = this.state;
    return (
      <Container className="dashboard">
        <Loader loader={this.state.isLoading} />
        <Row>
          <Col md={12} className='text-center my-2 pageHeadingCss'>
            {tl['dashboard'] ? tl['dashboard'] : 'Dashboard'}
          </Col>
        </Row>
        <CountDisplay data={this.state.dashboardData} tl={tl} />
        {/* #Session Expire Modal */}
        {this.state.isOpenSessionExpiredModal ?
          <SessionExpireModal
            isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
            tl={tl}
          />
          : null
        }
      </Container >
    );
  }
}

export default Dashboard;
import React, { PureComponent } from 'react';
import MenuIcon from 'mdi-react/MenuIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';
import config from '../../../config/config';
import fetchMethodRequest from '../../../config/service';

const noProfileImage = require('../../../assets/img/profile/userProfile.png');

class TopbarProfile extends PureComponent {
    constructor() {
        super();
        this.state = {
            collapse: false,
            userData: null,
            t: JSON.parse(localStorage.getItem('langJson')),
            lang: (localStorage.getItem('lang') && localStorage.getItem('lang') !== 'undefined' ? localStorage.getItem('lang') : null),
        };
        this.getUserData();
    }

    // Get login profile data/photo
    getUserData = async () => {
        let userData = await localStorage.getItem('loginCredentials');
        userData = JSON.parse(userData);
        if (userData) {
            await this.setState({ userData: userData });
        }
    }

    changeLanguage = async (lang) => {
        let url = `auth/languages?language=${lang}`;
        fetchMethodRequest('GET', url).then(async resp => {
            if (resp) {
                await localStorage.setItem('lang', lang);
                await localStorage.setItem('langJson', JSON.stringify(resp));
                await this.setState({ t: resp, lang: lang })
            }
        }).catch(err => {
            return err;
        })
    }

    // Profile PopModal toggle handler
    toggle = () => {
        this.setState(prevState => ({ collapse: !prevState.collapse }));
    };

    render() {
        const { tl } = this.props;
        const { collapse } = this.state;
        return (
            <div className="topbar__profile ml-0">
                <button className="topbar__avatar" type="button" onClick={this.toggle}>
                    <img
                        className="hideInMobile topbar__avatar-img"
                        src={this.state.userData && this.state.userData.photo ? `${config.imgUrl}customer/${JSON.parse(localStorage.getItem('loginCredentials')).photo}` : noProfileImage}
                        alt=''
                    />
                    <MenuIcon className="showInMobile topbar__icon mx-1" />
                </button>
                {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
                <Collapse isOpen={collapse} className="topbar__menu-wrap">
                    <div className="topbar__menu topbarMenu">
                        {localStorage.getItem('loginCredentials') && localStorage.getItem('loginCredentials') !== 'undefined' ?
                            <div>
                                <TopbarMenuLink tl={tl} className='showInMobile' title={'home'} icon="home" path="/" toggle={this.toggle} />
                                <TopbarMenuLink tl={tl} className='showInMobile' title={'myProperties'} icon="apartment" path="/my_properties" toggle={this.toggle} />
                                <TopbarMenuLink tl={tl} className='showInMobile' title={'search'} icon="magnifier" path="/search_properties" toggle={this.toggle} />
                                <TopbarMenuLink tl={tl} className='showInMobile' title={'favourites'} icon="heart" path="/favourite_properties" toggle={this.toggle} />

                                <TopbarMenuLink tl={tl} title={'savedSearches'} icon="magnifier" path="/savedSearchesList" toggle={this.toggle} />
                                <TopbarMenuLink tl={tl} title={'matchedProps'} icon="apartment" path="/matchedPropertiesList" toggle={this.toggle} />
                                <TopbarMenuLink tl={tl} title={'settings'} icon="cog" path="/settings" toggle={this.toggle} changeLanguage={this.props.changeLanguage} />
                                <TopbarMenuLink tl={tl} title={'logout'} icon="exit" path="/log_in" toggle={this.toggle} />
                            </div>
                            : null
                        }
                    </div>
                </Collapse>
            </div >
        );
    }
}
export default TopbarProfile
const Config = {
    // Project Name
    projectName: 'magicBricks',

    // Project APIs
    // apiUrl: 'https://mlsdevapi.dosystemsinc.com/api/',
    // imgUrl: 'https://mlsdevapi.dosystemsinc.com/images/attachment/',
    // resolutionUrl: 'https://mlsdevapi.dosystemsinc.com/images/newResolutions/',
    // profileUrl: 'https://mlsdevapi.dosystemsinc.com/images/agents/',

    apiUrl: 'https://mlstestapi.dosystemsinc.com/api/',
    imgUrl: 'https://mlstestapi.dosystemsinc.com/images/attachment/',
    resolutionUrl: 'https://mlstestapi.dosystemsinc.com/images/newResolutions/',
    profileUrl: 'https://mlstestapi.dosystemsinc.com/images/employees/',

    // apiUrl: 'https://mlsliveapi.dosystemsinc.com/api/',
    // imgUrl: 'https://mlsliveapi.dosystemsinc.com/images/attachment/',
    // resolutionUrl: 'https://mlsliveapi.dosystemsinc.com/images/newResolutions/',
    // profileUrl: 'https://mlsliveapi.dosystemsinc.com/images/employees/',

    // Unique Social Login Ids:To be generated by registering our app in respective Developer.social sites
    fbLoginAppId: '343001563596549',
    googleLoginClientId: '638798836799-irqqdu1aealepvrfj0ell8q21ogvm90j.apps.googleusercontent.com',

    // Server Error Message
    serverErrMessage: 'Could Not reach server',

    // Theme Colors
    themeColor: '#ff7b00',
    themeBlue: '#173471',
    themeGray: '#787985',
    themeWhite: '#ffffff',
    themeGreen: '#4CAF50',
    modalBackground: '#000000cc',

    //Settings
    currencySymbol: '₹',
    areaUnits: 'Sq.Yds',
    shareLimit: 10,
    borderValidation: false,

    //regex
    messages: true,
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    passwordRegex: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/,
    aadharcardNumberRegex: /^([0-9]){12}$/,
    pancardNumberRegex: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
    phoneNumberRegex: /^(0|91|\+91)?-?[6789]\d{9}$/,
    RERANumberRegex: /^[0-9]{4,8}$/,

    // server response codes
    updateResCode: 205,
    deleteResCode: 206,

    // Date Formats
    datePlaceholder: '--/--/----',
    dateFormat: 'MM/DD/YYYY',
    dateDashFormat: 'YYYY-MM-DD',
    basicDateFromat: 'MM/DD/YYYY HH:mm A',
    descDateFromat: 'MMM DD YYYY HH:mm A',
    dateDmyFormat: 'DD-MM-YYYY',
    fullDateDmyFormat: 'DD-MM-YYYY hh:mm:ss A',

    timeFormat: 'HH:mm',
    syncTimeFormat: 'hh:mm A, MM-DD-YYYY',
    lastModifiedDateFormat: 'MM/DD/YYYY HH:mm',
    dateTimeFormat: 'MM/DD/YYYY hh:mm',
    fullDateFormat: 'YYYY-MM-DD HH:mm:ss',
    dbDateFormat: 'YYYY-MM-DD[T]HH:mm:ss.SSS',
    dbOnlyDateFormat: 'YYYY-MM-DD[T]00:00:00',
    ESTTimezone: "America/New_York",

};
export default Config;
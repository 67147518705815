import React from 'react';
import { Input, FormFeedback, Button } from 'reactstrap';
import { Redirect } from 'react-router-dom';

// Toaster UI
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

// config file
import config from '../../../../config/config';
import Loader from '../../../App/Loader';
import fetchMethodRequest from '../../../../config/service';

export default class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.buttonActionType = null;
        this.state = {
            email: '',
            validate: {
                newPassword: false,
                confirmPassword: false
            },
            isActivated: false,
            newPassword: '',
            confirmPassword: '',
            isLoading: true,
            tl: {},
        };
    }

    componentDidMount = async () => {
        if (localStorage.getItem('langJson')) {
            let tl = JSON.parse(localStorage.getItem('langJson'));
            await this.setState({
                tl: tl,
                isLoading: false,
            })
        } else {
            await this.changeLanguage();
        }
    }


    // Get Lang if not in local
    changeLanguage = async () => {
        let lang;
        let lan = (localStorage.getItem('lang') && localStorage.getItem('lang') !== 'undefined') ? localStorage.getItem('lang') : null;
        if (lan && lan) {
            lang = lan;
        } else {
            lang = 'en'
        }

        let url = `auth/languages?language=${lang}`;
        await fetchMethodRequest('GET', url).then(async resp => {
            if (resp) {
                await localStorage.setItem('lang', lang);
                await localStorage.setItem('langJson', JSON.stringify(resp));
                await this.setState({
                    tl: resp,
                    lang: lang,
                    isLoading: false,
                })
            } else {
                await this.setState({
                    isLoading: false,
                })
            }
        }).catch(async err => {
            await this.setState({
                isLoading: false,
            })
            return err;
        })
    }

    // Hanlde on input change
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    // Handle login user data
    handleUserPassword = () => {
        this.setState({ isLoading: true });
        let userBody = {
            newPassword: this.state.newPassword,
            confirmPassword: this.state.confirmPassword,
            enEmail: this.props.match.params.encodedEmailId,
            entityType: 'user',
        }
        let url;
        if (this.props.match.params && this.props.match.params.active) {
            url = 'auth/changeRecoverPassword?active=true';
        } else {
            url = 'auth/changeRecoverPassword';
        }
        return fetch('POST', url, userBody)
            .then((response) => {
                if (response && response.respCode && response.respCode === 200) {
                    this.setState({ isLoading: false });
                    if (response.details && response.details._id) {
                        let userDetails = response.details;
                        if (response.accessToken) {
                            let tokenInfo = {
                                accessToken: response.accessToken,
                                refreshToken: response.refreshToken,
                                tokenExpires: response.tokenExpires,
                            };
                            userDetails = { ...userDetails, ...tokenInfo };
                        }
                        // save user credentials in storage
                        localStorage.setItem('loginCredentials', JSON.stringify(userDetails));
                    }
                    this.setState({ isActivated: true });
                    // display message
                    showToasterMessage(response.respMessage, 'success');
                } else if (response && response.errorMessage) {
                    this.setState({ isLoading: false });
                    // display message
                    showToasterMessage(response.errorMessage, 'error');
                } else {
                    this.setState({ isLoading: false });
                }
                this.clearInputFields();
            }).catch((err) => {
                return err;
            });
    }

    // check input fields empty or not
    checkCredentials = () => {
        const { validate } = this.state
        if (!this.state.newPassword) {
            validate.newPassword = 'has-danger';
        }
        if (!this.state.confirmPassword) {
            validate.confirmPassword = 'has-danger'
        }
        if (this.state.confirmPassword && this.state.newPassword) {
            if (this.state.confirmPassword !== this.state.newPassword) {
                validate.confirmPassword = 'has-danger'
            }
        }
        this.setState({ validate })
    }

    // validate password
    validatePassword = (e) => {
        let validate = this.state.validate;
        if (e.target.value.length > 6) {
            var test = config.passwordRegex.test(e.target.value);
            if (test) {
                validate[e.target.name] = 'has-success';
            } else {
                validate[e.target.name] = 'has-danger';
            }
            this.setState({ validate: validate })
        }
    }

    // clear input data after submit password changes
    clearInputFields = () => {
        let validate = {
            newPassword: false,
            confirmPassword: false
        }
        this.setState({
            newPassword: '',
            confirmPassword: '',
            validate: validate
        })
    }

    render() {
        const { tl } = this.state;
        return (
            <form className="form">
                <Loader loader={this.state.isLoading} />
                <h3 className='mx-auto mb-3'>
                    {tl['resetPassword'] ? tl['resetPassword'] : 'Reset Password'}
                </h3>
                <div className='row form--group'>
                    <Input className='inputLogin mb-0'
                        type="password"
                        name="newPassword"
                        valid={this.state.validate.newPassword === 'has-success'}
                        invalid={this.state.validate.newPassword === 'has-danger'}
                        placeholder={tl['newPassword'] ? tl['newPassword'] : "New Password"}
                        value={this.state.newPassword}
                        onChange={(e) => {
                            this.validatePassword(e)
                            this.handleChange(e)
                        }}
                    />
                    {!this.state.newPassword ?
                        <FormFeedback className='formErrorText'>
                            {tl['newPasswordReq'] ? tl['newPasswordReq'] : 'Please enter New Password'}
                        </FormFeedback> :
                        null}
                    <Input className='inputLogin mb-0 mt-3'
                        type="password"
                        name="confirmPassword"
                        valid={this.state.validate.confirmPassword === 'has-success'}
                        invalid={this.state.validate.confirmPassword === 'has-danger'}
                        placeholder={tl['confirmPassword'] ? tl['confirmPassword'] : "Confirm Password"}
                        value={this.state.confirmPassword}
                        onChange={(e) => {
                            this.validatePassword(e)
                            this.handleChange(e)
                        }}
                    />
                    {!this.state.confirmPassword && this.state.newPassword ?
                        <FormFeedback className='formErrorText' >
                            {tl['confirmPasswordReq'] ? tl['confirmPasswordReq'] : 'Please enter Confirm Password'}
                        </FormFeedback> :
                        null}

                    {this.state.newPassword && this.state.confirmPassword && (this.state.newPassword !== this.state.confirmPassword)
                        ?
                        <FormFeedback className='formErrorText' >
                            {tl['passwordsNotMatch'] ? tl['passwordsNotMatch'] : 'Passwords did not match'}
                        </FormFeedback>
                        : null
                    }
                    <Button
                        className="loginBtn mt-3 mb-0"
                        color='primary'
                        block
                        onClick={this.state.newPassword && this.state.confirmPassword && (this.state.newPassword === this.state.confirmPassword)
                            ? this.handleUserPassword : this.checkCredentials}
                    >
                        {tl['submit'] ? tl['submit'] : 'Submit'}
                    </Button>
                </div>

                {/* Re-direct to login upon successful password reset */}
                {this.state.isActivated ?
                    <Redirect
                        to={{
                            pathname: 'log_in',
                            state: { tl: tl }
                        }}
                    />
                    : null
                }
            </form>
        );
    }
}
import React, { PureComponent } from 'react';
import { Collapse } from 'reactstrap';
import DownIcon from 'mdi-react/ChevronDownIcon';

const gb = `${process.env.PUBLIC_URL}/img/language/gb.png`;
const fr = `${process.env.PUBLIC_URL}/img/language/fr.png`;

const GbLng = () => (
    <span className="topbar__language-btn-title">
        <img src={gb} alt="gb" />
        <span>EN</span>
    </span>
);

const FrLng = () => (
    <span className="topbar__language-btn-title">
        <img src={fr} alt="fr" />
        <span>FR</span>
    </span>
);


class TranslationTool extends PureComponent {
    constructor() {
        super();
        this.state = {
            collapse: false,
            mainButtonContent: <GbLng />,
        };
    }

    // Open or Close Language selector pop-up
    toggle = () => {
        this.setState(prevState => ({ collapse: !prevState.collapse }));
    };

    // Change language based upon selection
    changeLanguage = async (lang) => {
        this.toggle();
        switch (lang) {
            case 'en':
                this.setState({ mainButtonContent: <GbLng /> });
                break;
            case 'fr':
                this.setState({ mainButtonContent: <FrLng /> });
                break;
            default:
                this.setState({ mainButtonContent: <GbLng /> });
                break;
        }
        await this.props.changeLanguage(lang);
        await this.toggle();
    }

    render() {
        const { mainButtonContent, collapse } = this.state;
        const { lang } = this.props;
        return (
            <div className="topbar__collapse topbar__collapse--language">
                {collapse && <button className="topbar__back" type="button" onClick={this.toggle} />}
                <button className="topbar__btn loginTranslateButton" type="button" onClick={this.toggle}>
                    {lang && lang ?
                        (lang === 'en') ?
                            <GbLng />
                            : (lang === 'fr') ?
                                <FrLng />
                                : mainButtonContent
                        : mainButtonContent
                    }
                    <DownIcon className="topbar__icon mx-0" />
                    <Collapse
                        isOpen={collapse}
                        className="topbar__collapse-content topbar__collapse-content--language"
                    >
                        <span
                            className="topbar__language-btn"
                            type="button"
                            onClick={() => this.changeLanguage('en')}
                        >
                            <GbLng />
                        </span>
                        <span
                            className="topbar__language-btn"
                            type="button"
                            onClick={() => this.changeLanguage('fr')}
                        >
                            <FrLng />
                        </span>
                    </Collapse>
                </button>
            </div>
        );
    }
}

export default (TranslationTool);

import React from 'react';
import { Input, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

// UI Components
import Loader from '../../../App/Loader';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

// config files
import config from '../../../../config/config';
import fetch from '../../../../config/service';

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            validate: {
                emailState: false,
                isLoading: false
            },
            tl: {}
        };
    }

    componentDidMount = async () => {
        let tl = {}
        if (this.props.location && this.props.location.state && this.props.location.state.tl) {
            tl = this.props.location.state.tl
        } else {
            tl = JSON.parse(localStorage.getItem('langJson'));
        }
        await this.setState({
            tl: tl,
        })
    }

    // on value change in input
    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    // handle login user data
    handleUserPassword = () => {
        this.setState({ isLoading: true });
        if (this.state.validate && this.state.validate.emailState !== 'has-danger') {
            let body = { 'entityType': 'user' };
            return fetch('POST', `auth/forgotPassword?email=${this.state.email}`, body)
                .then((response) => {
                    if (response && response.respCode) {
                        this.setState({
                            email: '',
                            validate: {},
                            isLoading: false,
                        })
                        showToasterMessage(response.respMessage, 'success');
                    } else if (response && response.errorMessage) {
                        showToasterMessage(response.errorMessage, 'error');
                        this.setState({ isLoading: false });
                    }
                }).catch((err) => {
                    return err;
                });
        }
    }

    // Check Email field
    checkCredentials = () => {
        const { validate } = this.state
        if (!this.state.email) {
            validate.emailState = 'has-danger'
        }
        this.setState({ validate })
    }

    // validate email address
    validateEmail(e) {
        const { validate } = this.state
        if (e.target.value && e.target.value.length > 6) {
            if (config.emailRegex.test(e.target.value)) {
                validate.emailState = 'has-success'
            } else {
                validate.emailState = 'has-danger'
            }
        }
        this.setState({ validate })
    }

    render() {
        const { tl } = this.state;
        return (
            <form className="form">
                <Loader loader={this.state.isLoading} />
                <h3 className='mx-auto mb-3'>
                    {tl['forgotPassword'] ? tl['forgotPassword'] : 'Forgot Password'}
                </h3>
                <div className="form__form-group">
                    <span className="form__form-group-label">
                        {tl['email'] ? tl['email'] : 'Email'} *
                    </span>
                    <div className="form__form-group-field">
                        <Input
                            type="email"
                            name="email"
                            placeholder={tl['email'] ? tl['email'] : 'Email'}
                            value={this.state.email}
                            onChange={(e) => {
                                this.validateEmail(e)
                                this.handleChange(e)
                            }}
                        />
                    </div>
                    {!this.state.email && this.state.validate && this.state.validate.emailState === 'has-danger' ?
                        <span className="formErrorText">
                            {tl['errorEmailReq'] ? tl['errorEmailReq'] : 'Please Enter Email'}
                        </span>
                        : null
                    }
                    {this.state.email && this.state.validate && this.state.validate.emailState === 'has-danger' ?
                        <span className="formErrorText">
                            {tl['errorEmailValid'] ? tl['errorEmailValid'] : 'Please Enter Valid Email'}
                        </span>
                        : null
                    }
                </div>

                <div className="form__form-group">
                    <Button
                        className="account__btn mb-0"
                        color="primary"
                        onClick={this.state.email ? this.handleUserPassword : this.checkCredentials}
                    >
                        {tl['submit'] ? tl['submit'] : 'Submit'}
                    </Button>
                </div>
                <div className='account__forgot-password'>
                    <Link
                        className='p-0 pl-1'
                        to={{ pathname: 'log_in', state: { tl: tl } }}
                    >
                        {tl['backToLogin'] ? tl['backToLogin'] : 'Back to Login'}
                    </Link>
                </div>
            </form>
        );
    }
}
export default ForgotPassword;
import React, { PureComponent } from 'react';
import LogInForm from './components/LogInForm';
import fetchMethodRequest from '../../config/service';
import config from '../../config/config';

class LogIn extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tl: {},
    }
    if (!this.props.location || !this.props.location.state || !this.props.location.state.tl) {
      this.changeLanguage('cons');
    }
  }

  componentDidMount = async () => {
    // Clearing LocalStorage
    localStorage.removeItem('myPropsSelected')
    localStorage.removeItem('searchPropsSelected')
    localStorage.removeItem('matchedPropsSelected')

    localStorage.removeItem('myIsGlobalChecked')
    localStorage.removeItem('searchIsGlobalChecked')
    localStorage.removeItem('matchedIsGlobalChecked')

    localStorage.removeItem('myPropsDeselected');
    localStorage.removeItem('searchPropsDeselected');
    localStorage.removeItem('matchedPropsDeselected');

    localStorage.removeItem('myClientCriteria')
    localStorage.removeItem('myFilterCriteria')
    localStorage.removeItem('fromScreen')
    localStorage.removeItem('loginCredentials')

    //Getting Language Object from props
    let tl = {}
    if (this.props.location && this.props.location.state && this.props.location.state.tl) {
      tl = this.props.location.state.tl
      await this.setState({
        tl: tl,
      })
    }
  }

  changeLanguage = async (type) => {
    let lang;
    if (type && type === 'cons') {
      let lan = (localStorage.getItem('lang') && localStorage.getItem('lang') !== 'undefined' ? localStorage.getItem('lang') : null);
      if (lan && lan) {
        lang = lan;
      } else {
        lang = 'en'
      }
    } else {
      lang = type;
    }
    let url = `auth/languages?language=${lang}`;
    await fetchMethodRequest('GET', url).then(async resp => {
      if (resp) {
        await localStorage.setItem('lang', lang);
        await localStorage.setItem('langJson', JSON.stringify(resp));
        await this.setState({ tl: resp, lang: lang })
      }
    }).catch(err => {
      return err;
    })
  }

  render() {
    const { tl } = this.state;
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="account__card">
            <div className="text-center mb-2">
              <h3 className="account__title">
                {tl['welcomeTo'] ? tl['welcomeTo'] + ' ' : 'Welcome to '}
                <span className="account__logo">
                  <span className="account__logo-accent">
                    {tl[config.projectName] ? tl[config.projectName] : 'Magic Bricks'}
                  </span>
                </span>
              </h3>
            </div>
            <LogInForm onSubmit tl={tl} />
          </div>
        </div>
      </div >
    )
  }
}

export default LogIn;

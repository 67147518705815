import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

import LoginForm from './LogInForm';

import config from '../../../config/config';

class LoginFormModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoginSuccess: false
        };
    }

    // On Login cancel
    closeLoginFormModal = async () => {
        await this.props.closeLoginFormModal();
    }

    // On Login Success
    onLoginSuccess = async (user) => {
        await this.props.closeLoginFormModal(user);
    }

    render() {
        const { tl } = this.props;
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpenLoginFormModal}
                    className={`modal-dialog-centered modal-dialog--primary otpVerifyModal formModalBorderRadius`}
                    toggle={() => this.closeLoginFormModal()}
                >
                    <Button
                        className='modalToggleButton'
                        color="primary"
                        close={true}
                        onClick={() => this.closeLoginFormModal()}
                    />
                    <ModalBody className='py-5'>
                        <h3 className="account__title">
                            <span className="account__logo">
                                <span className="account__logo-accent">
                                    {tl[config.projectName] ? tl[config.projectName] : 'Magic Bricks'}
                                </span>
                            </span>
                        </h3>
                        <LoginForm
                            tl={tl}
                            onLoginSuccess={this.onLoginSuccess}
                        />
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default LoginFormModal
import React from 'react';
import { Button, Modal } from 'reactstrap';
import config from '../../../config/config';

class ShareLimitModal extends React.Component {

    render() {
        const { tl } = this.props;
        return (
            <Modal
                isOpen={this.props.isOpenShareLimitModal}
                toggle={() => this.props.closeShareLimitModal()}
                className={`modal-dialog invalidFilterModal modal-dialog-centered modal-dialog--primary formModalBorderRadius`}
            >
                <Button
                    className='modalToggleButton'
                    color="primary"
                    close={true}
                    onClick={() => this.props.closeShareLimitModal()}
                />
                <h4>
                    {tl['warning'] ? tl['warning'] : 'Warning'}
                </h4>
                <h5 className='my-3'>
                    {tl['maxShareLimit'] ? tl['maxShareLimit'] : 'Maximum share limit is'}{' '}{config.shareLimit}
                </h5>
                <div className='text-center'>
                    <Button
                        color='primary'
                        className='buttonTextWeight mt-2 mb-0 mx-0'
                        onClick={this.props.closeShareLimitModal}
                    >
                        {tl['ok'] ? tl['ok'] : 'Ok'}
                    </Button>
                </div>
            </Modal>
        );
    }
}

export default ShareLimitModal
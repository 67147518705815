import React from 'react';
import { Link } from 'react-router-dom';
import Image404 from '../../../shared/img/404/404.png';

const NotFound404 = () => {
  let permissions = JSON.parse(localStorage.getItem('loginCredetials'));
  let path;
  if (permissions) {
    path = '/';
  } else {
    path = '/';
  }
  return <div className="not-found" style={{ height: '100vh' }}>
    <div className="not-found__content">
      <img className="not-found__image" src={Image404} alt="404" />
      <h3 className="not-found__info">Ooops! Page Not Found :(</h3>
      <Link className="btn btn-primary" to={path}>Home</Link>
    </div>
  </div>
}

export default NotFound404;

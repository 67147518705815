import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

import DefaultInput from '../../../shared/components/form/DefaultInput';

import { Field, reduxForm } from 'redux-form';

import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

import SessionExpireModal from '../../SessionExpire/sessionExpireModal';

import validate from '../../Validations/validate';
import configMessages from '../../../config/configMessages';

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField)

class OtpVerifyModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSmsSent: false,
            otp: '',
        };
    }

    componentDidMount = async () => {
    }

    // Handle submit button
    submitPhone = async (values) => {
        let phoneNumber = values.phoneNumber;
        let user = await JSON.parse(localStorage.getItem('loginCredentials'));
        let body = {
            email: user.email,
            phoneNumber: phoneNumber.length === 10 ? '91' + phoneNumber : phoneNumber,
            entityType: 'customer'
        }
        if (user && user.email && phoneNumber) {
            fetchMethodRequest('POST', 'auth/sendOtp', body).then(async resp => {
                if (resp && resp.respCode) {
                    showToasterMessage(resp.respMessage, 'success');
                    await this.setState({
                        isSmsSent: true,
                        phoneNumber: phoneNumber
                    })
                    this.props.reset()
                } else {
                    if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                            phoneNumber: phoneNumber
                        })
                    } else {
                        this.setState({
                            isLoading: false,
                            phoneNumber: phoneNumber
                        });
                        showToasterMessage(resp.errorMessage, 'error');
                    }
                }
            }).catch(err => {
                return err
            })
        }
    }

    submitOtp = async (values) => {
        let user = await JSON.parse(localStorage.getItem('loginCredentials'));
        let body = {
            email: user.email,
            otp: values.otp,
            entityType: 'customer',
            phoneNumber: this.state.phoneNumber.length === 10 ? '91' + this.state.phoneNumber : this.state.phoneNumber,
        }
        if (user && user.email && values.otp) {
            fetchMethodRequest('POST', 'auth/verifyOtp', body).then(async resp => {
                if (resp && resp.respCode) {
                    showToasterMessage(resp.respMessage, 'success');
                    await this.setState({
                        isSmsSent: false,
                    })
                    user.isOtpVerified = true;
                    user.phoneNumber = this.state.phoneNumber;
                    await localStorage.setItem('loginCredentials', JSON.stringify(user))
                    await this.props.closeOtpVerifyModal(this.state.phoneNumber);
                } else {
                    if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                        })
                    } else {
                        this.setState({ isLoading: false });
                        showToasterMessage(resp.errorMessage, 'error');
                    }
                }
            })
        }
    }

    resendOtp = async () => {
        let body = { phoneNumber: this.state.phoneNumber }
        await this.submitPhone(body);
    }

    handleChange = otp => this.setState({ otp });

    render() {
        const { tl, handleSubmit } = this.props;
        const { isSmsSent } = this.state;
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpenOtpVerifyModal}
                    className={`modal-dialog-centered modal-dialog--primary otpVerifyModal formModalBorderRadius`}
                    toggle={() => this.props.closeOtpVerifyModal()}
                >
                    <Button
                        className='modalToggleButton'
                        color="primary"
                        close={true}
                        onClick={() => this.props.closeOtpVerifyModal()}
                    />
                    <ModalBody className='px-0 py-3'>
                        <form
                            className='form justify-content-center'
                            onSubmit={handleSubmit(isSmsSent ? this.submitOtp : this.submitPhone)}>
                            <h4 className="mb-3">
                                {tl['verifyPhoneText'] ? tl['verifyPhoneText'] : 'Please Verify your Phone Number'}!
                            </h4>
                            {isSmsSent ?
                                <div className="form__form-group col-sm-9">
                                    <div className="form__form-group-field">
                                        <Field
                                            name='otp'
                                            component={DefaultInput}
                                            placeholder={tl['enterOtp'] ? tl['enterOtp'] : 'Please enter 6 digit OTP'}
                                            validate={required}
                                            maxLength={6}
                                        />
                                    </div>
                                    <div className="form__form-group-field redendOtpText">
                                        <span
                                            onClick={this.resendOtp}
                                        >
                                            {tl['resendOtp'] ? tl['resendOtp'] : 'Resend Otp'}
                                        </span>
                                    </div>
                                </div>
                                :
                                <div className="form__form-group col-sm-9">
                                    <div className="form__form-group-field">
                                        <Field
                                            name='phoneNumber'
                                            component={DefaultInput}
                                            type='phone'
                                            placeholder={tl['enter10digitPhoneNumber'] ? tl['enter10digitPhoneNumber'] : 'Please enter 10 digit Phone Number'}
                                            validate={required}
                                        />
                                    </div>
                                </div>
                            }
                            <div className="col-12 px-0">
                                <Button
                                    color='primary'
                                    type="submit"
                                    className='otpModalButtons'
                                >
                                    {isSmsSent ?
                                        tl['verify'] ? tl['verify'] : 'Verify'
                                        : tl['submit'] ? tl['submit'] : 'Submit'
                                    }
                                </Button>
                            </div>
                        </form>
                    </ModalBody>
                </Modal>

                {/* #Session Expire Modal */}
                {this.state.isOpenSessionExpiredModal ?
                    <SessionExpireModal
                        isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                        tl={tl}
                    />
                    : null
                }
            </div>
        );
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
OtpVerifyModal = reduxForm({
    form: "otpFormModal", // a unique identifier for this form
    validate,
})(OtpVerifyModal);

export default OtpVerifyModal
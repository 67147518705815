import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import Avatar from 'react-avatar';
// Redux
import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { connect } from 'react-redux';

import CheckIcon from 'mdi-react/CheckCircleIcon';
import EditIcon from 'mdi-react/PencilIcon';

// Fields
import DefaultInput from '../../../shared/components/form/DefaultInput';

import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

import SessionExpireModal from '../../SessionExpire/sessionExpireModal';

import validate from '../../Validations/validate';
import configMessages from '../../../config/configMessages';
import socket from '../../../config/Socket';
import config from '../../../config/config';
import fetchMethodRequest from '../../../config/service';

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField)

class ContactSellerModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNo: '',
            maskedPhoneNo: '',
            showPhoneNumber: '',
            PropertyDetails: {},
        };
    }

    componentDidMount = async () => {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        let PropertyDetails = await this.props.PropertyDetails;
        let phoneNo = PropertyDetails.phoneNumber ?
            PropertyDetails.phoneNumber :
            PropertyDetails.customerId && PropertyDetails.customerId.phoneNumber ?
                PropertyDetails.customerId.phoneNumber :
                ''

        let phoneStr = phoneNo.toString()
        let maskedPhoneNo = phoneStr.substr(0, phoneStr.length - 8) + ' * * * * *';
        await this.setState({
            PropertyDetails: PropertyDetails,
            phoneNo: phoneNo,
            maskedPhoneNo: maskedPhoneNo,
        })
        if (user && user.email && this.props.change) {
            this.props.change('email', user && user.email)
        }
    }

    // Otp verify success handler
    verifySuccess = async (values) => {
        let PropertyDetails = this.state.PropertyDetails;
        if (PropertyDetails.customerId) {
            socket.emit(
                'agentViewed',
                {
                    contactName: this.state.contactName,
                    contactPhoneNumber: values.phoneNumber,
                    contactEmail: values.email,
                    customerEmail: PropertyDetails.email
                }
            )
        } else {
            socket.emit(
                'agentViewed',
                {
                    contactName: this.state.contactName,
                    contactPhoneNumber: values.phoneNumber,
                    contactEmail: values.email,
                    customerEmail: PropertyDetails.email
                }
            )
        }
        await this.setState({
            showPhoneNumber: true,
        })

        showToasterMessage('Contact shared successfully', 'success')
    }

    // Close Contact Seller Modal 
    closeContactSellerModal = async () => {
        await this.props.closeContactSellerModal(this.state.isVerifySuccess);
    }

    // On contact seller form submit
    submit = async (values) => {
        let phoneNumber = values.phoneNumber;
        let body = {
            email: values.email,
            phoneNumber: phoneNumber.length === 10 ? '91' + phoneNumber : phoneNumber,
            entityType: 'clients'
        }
        if (values && values.email && phoneNumber) {
            fetchMethodRequest('POST', 'auth/sendOtp', body).then(async resp => {
                if (resp && resp.respCode) {
                    showToasterMessage(resp.respMessage, 'success');
                    this.props.reset()
                    await this.setState({
                        isSmsSent: true,
                        phoneNumber: phoneNumber,
                        email: values.email,
                        contactName: values.name,
                    })
                } else {
                    if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                            phoneNumber: phoneNumber,
                            email: values.email,
                            contactName: values.name,
                        })
                    } else {
                        this.setState({
                            isLoading: false,
                            phoneNumber: phoneNumber,
                            email: values.email,
                            contactName: values.name,
                        });
                        showToasterMessage(resp.errorMessage, 'error');
                    }
                }
            }).catch(err => {
                return err
            })
        }
    }

    // Resend otp click handler
    resendOtp = async () => {
        let body = {
            phoneNumber: this.state.phoneNumber,
            email: this.state.email,
        }
        await this.submit(body);
    }

    // Otp verification reject handler
    cancelOtpVerification = async () => {
        await this.setState({
            isSmsSent: false,
        })
    }

    // on otp submit
    submitOtp = async (values) => {
        let body = {
            email: this.state.email,
            otp: values.otp,
            entityType: 'clients',
            phoneNumber: this.state.phoneNumber.length === 10 ? '91' + this.state.phoneNumber : this.state.phoneNumber,
        }
        if (this.state.phoneNumber && this.state.email && values.otp) {
            fetchMethodRequest('POST', 'auth/verifyOtp', body).then(async resp => {
                if (resp && resp.respCode) {
                    await this.setState({
                        isSmsSent: false,
                        isVerifySuccess: true,
                    })
                    await this.verifySuccess(body);

                } else {
                    if (resp.errorMessage && resp.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                        })
                    } else {
                        this.setState({ isLoading: false });
                        showToasterMessage(resp.errorMessage, 'error');
                    }
                }
            })
        }
    }

    editPhoneNumber = async () => {
        await this.setState({
            isSmsSent: false,
        })
        if (this.props.load) {
            let body = {
                name: this.state.contactName,
                email: this.state.email,
                phoneNumber: this.state.phoneNumber,
            }
            await this.props.load(body);
        }
    }

    render() {
        const { phoneNo, maskedPhoneNo, showPhoneNumber, PropertyDetails, isSmsSent, isVerifySuccess } = this.state;
        const { handleSubmit, tl } = this.props;
        return (
            <div>
                <Modal
                    isOpen={this.props.isOpenContactSellerModal}
                    className={`modal-dialog-centered modal-dialog--primary otpVerifyModal formModalBorderRadius`}
                    toggle={() => this.closeContactSellerModal()}
                >
                    <Button
                        className='modalToggleButton'
                        color="primary"
                        close={true}
                        onClick={() => this.closeContactSellerModal()}
                    />
                    <ModalBody className='p-0'>
                        <div className='pd_nav_content_contact'>
                            <div className='pd_contact_childOne'>
                                {tl['contactSeller'] ? tl['contactSeller'] : 'Contact Seller'}
                            </div>
                            <div className='pd_contact_childTwo'>
                                <Avatar
                                    name={PropertyDetails.displayName ? PropertyDetails.displayName : PropertyDetails.customerId && PropertyDetails.customerId.displayName ? PropertyDetails.customerId.displayName : ''}
                                    round={true}
                                    size={40}
                                    maxInitials={2}
                                    color={config.themeColor}
                                    fgColor={config.themeWhite}
                                    textSizeRatio={2.8}
                                    className='mr-2'
                                />
                                <div className='text-left'>
                                    <p className='textCapitalize'>
                                        {
                                            PropertyDetails.displayName ? PropertyDetails.displayName : PropertyDetails.customerId && PropertyDetails.customerId.displayName ? PropertyDetails.customerId.displayName : ''
                                        }
                                    </p>
                                    <p className='mt-0'>
                                        {showPhoneNumber ?
                                            phoneNo
                                            : maskedPhoneNo
                                        }
                                    </p>
                                    {isVerifySuccess ?
                                        <p className='mt-0 useVerifiedNumberText'>
                                            {tl['pleaseUseRegisteredNumber'] ? tl['pleaseUseRegisteredNumber'] : 'Please use your registered number'}{' - '}
                                            {this.state.phoneNumber ? this.state.phoneNumber : ''}{' '}
                                            {tl['to'] ? tl['to'] : 'to'}{' '}
                                            {tl['contactTheSeller'] ? tl['contactTheSeller'] : 'contact the seller'}
                                        </p>
                                        : null
                                    }
                                </div>
                            </div>
                            <div className='pd_contactForm'>
                                {isSmsSent ?
                                    <form
                                        className='justify-content-center'
                                        onSubmit={handleSubmit(this.submitOtp)}>
                                        <div className="form">
                                            <h5 className="mb-3">
                                                <span>{tl['verifyPhoneText'] ? tl['verifyPhoneText'] : 'Please enter the verification code sent to'}{' '}</span>
                                                <span>{this.state.phoneNumber}</span>
                                                <EditIcon
                                                    className='contactSellerPhoneEdit'
                                                    onClick={() => this.editPhoneNumber()}
                                                />
                                            </h5>
                                            <div className="form__form-group">
                                                <div className="form__form-group-field">
                                                    <Field
                                                        name='otp'
                                                        component={DefaultInput}
                                                        placeholder={tl['enterOtp'] ? tl['enterOtp'] : 'Please enter 6 digit OTP'}
                                                        validate={required}
                                                        maxLength={6}
                                                    />
                                                </div>
                                                <div className="form__form-group-field redendOtpText">
                                                    <span
                                                        onClick={this.resendOtp}
                                                    >
                                                        {tl['resendOtp'] ? tl['resendOtp'] : 'Resend Otp'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 px-0 text-center mt-2">
                                            <Button
                                                color='primary'
                                                type="submit"
                                                className='mb-0 mt-2 mx-1'
                                            >
                                                {tl['verify'] ? tl['verify'] : 'Verify'}
                                            </Button>
                                        </div>
                                    </form>
                                    : isVerifySuccess ?
                                        <div className="mb-2">
                                            <h5 className='text-left otpVerifySuccessText'>
                                                <CheckIcon
                                                    color={config.themeGreen}
                                                    className='mr-2'
                                                />
                                                {tl['yourDetailsHaveBeenSent'] ? tl['yourDetailsHaveBeenSent'] : 'Your details have been sent'}!
                                            </h5>
                                        </div>
                                        :
                                        <form onSubmit={handleSubmit(this.submit)}>
                                            <div className="form">
                                                <div className="mb-2">
                                                    <h5>
                                                        {tl['getContactDetails'] ? tl['getContactDetails'] : 'Get Contact Details'}
                                                    </h5>
                                                </div>
                                                <div className="form__form-group">
                                                    <div className="form__form-group-field">
                                                        <Field
                                                            name='name'
                                                            component={DefaultInput}
                                                            type="name"
                                                            placeholder={tl['name'] ? tl['name'] : 'Name'}
                                                            validate={required}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form__form-group">
                                                    <div className="form__form-group-field">
                                                        <Field
                                                            name='phoneNumber'
                                                            component={DefaultInput}
                                                            type="phone"
                                                            placeholder={tl['phone'] ? tl['phone'] : 'phone'}
                                                            validate={required}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="form__form-group">
                                                    <div className="form__form-group-field">
                                                        <Field
                                                            name='email'
                                                            component={DefaultInput}
                                                            placeholder={tl['email'] ? tl['email'] : 'Email'}
                                                            validate={required}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 px-0 text-center mt-2">
                                                <Button
                                                    className="mb-0 mt-2 mx-1"
                                                    color="primary"
                                                    type='submit'
                                                >
                                                    {tl['getContactDetails'] ? tl['getContactDetails'] : 'Get contact details'}
                                                </Button>
                                            </div>
                                        </form>
                                }
                            </div>
                        </div>
                    </ModalBody>
                </Modal>

                {/* #Session Expire Modal */}
                {this.state.isOpenSessionExpiredModal ?
                    <SessionExpireModal
                        isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                        tl={tl}
                    />
                    : null
                }
            </div>
        );
    }
}

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ContactSellerModal = reduxForm({
    form: "contactSellerModal", // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(ContactSellerModal);

// You have to connect() to any reducers that you wish to connect to yourself
ContactSellerModal = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(ContactSellerModal);

export default ContactSellerModal
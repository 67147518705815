import React, { PureComponent } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import SearchIcon from 'mdi-react/SearchIcon';

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props} className='px-2 py-0'>
      <SearchIcon />
    </components.DropdownIndicator>
  );
};

class SelectField extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })),
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
      }),
    ]),
  };

  static defaultProps = {
    placeholder: '',
    options: [],
  };

  constructor(props) {
    super(props)
    this.state = {
      value: null,
      defaultValue: null
    }
  }

  componentDidMount = () => {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }
  handleChange = async (selectedOption) => {
    const { onChange } = this.props.input;
    if (selectedOption) {
      this.setState({ value: selectedOption, label: selectedOption.label });
      onChange(selectedOption.value);
      if (this.props.type && this.props.type === 'clientSelection') {
        await this.props.getSelectedOption(selectedOption)
      }
    } else {
      this.props.input.onChange(null)
      this.setState({
        value: null,
        label: null
      })
    }
  };

  handleInputChange = (val) => {
    let valLength = val.length;
    let type = this.props.type;
    if (type && (type === 'clientSelection' || type === 'city' || type === 'locality')) {
      this.setState({
        inputLength: valLength,
      })
    }
  }

  handleFocus = () => {
    let type = this.props.type;
    if (type && (type === 'clientSelection' || type === 'city' || type === 'locality')) {
      this.setState({
        isMenuOpenOnFocus: false,
      })
    } else {
      this.setState({
        isMenuOpenOnFocus: true,
      })
    }
  }

  handleBlur = () => {
    let type = this.props.type;
    if (type && (type === 'clientSelection' || type === 'city' || type === 'locality')) {
      this.setState({
        isMenuOpenOnFocus: false,
      })
    } else {
      this.setState({
        isMenuOpenOnFocus: false,
      })
    }
  }

  render() {
    const { placeholder, options, isDisable, type
    } = this.props;
    const { name, value } = this.props.input;
    const { inputLength } = this.state;

    const isValue = { label: this.state.label ? this.state.label : value, value: value };
    const selectValue = isValue && isValue.value ? isValue : this.state.value ? this.state.value : this.state.defaultValue ? this.state.defaultValue : null

    if (!value && !this.state.value) {
      if (this.props.defaultValue && this.state.defaultValue) {
        this.handleChange(this.state.defaultValue);
      }
    }

    return (
      <Select
        name={name}
        value={selectValue}
        tempValue={'sdfgh'}
        onChange={this.handleChange}
        options={options}
        clearable={true}
        className={this.props.className ? this.props.className + " react-select" : "react-select"}
        placeholder={placeholder}
        components={(type && (type === 'clientSelection' || type === 'city' || type === 'locality')) ? { DropdownIndicator } : null}
        classNamePrefix="react-select"
        style={isDisable ? { background: 'lightgray', border: "none" } : { background: 'white' }} {...placeholder} isDisabled={isDisable}
        maxMenuHeight={this.props.maxHeight ? this.props.maxHeight : null}
        onInputChange={(val) => this.handleInputChange(val)}
        isClearable={(type && (type === 'clientSelection' || type === 'city' || type === 'locality')) ? true : null}
        onFocus={this.handleFocus}
        onBlur={this.handleBlur}
        blurInputOnSelect={true}
        menuIsOpen={
          ((type === 'clientSelection' || type === 'city' || type === 'locality') || (type === 'locality' && this.props.city)) ?
            (inputLength && inputLength >= 3 ? true : false)
            : this.state.isMenuOpenOnFocus
        }
      />
    );
  }
}

const renderSelectField = (props) => {
  const { meta } = props;
  return (
    <div className="form__form-group-input-wrap">
      <SelectField
        {...props}
      />
      {meta.touched && meta.error && <span className="form__form-group-error">{meta.error}</span>}
    </div>
  );
};

renderSelectField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    name: PropTypes.string,
  }),
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  placeholder: PropTypes.string,
};

renderSelectField.defaultProps = {
  meta: null,
  options: [],
  placeholder: '',
};

export default renderSelectField;

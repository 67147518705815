import React, { PureComponent } from 'react';
import { Collapse, CardBody, Card, Button } from 'reactstrap';
import ScrollBar from 'react-smooth-scrollbar';

import BellIcon from 'mdi-react/BellIcon';
import PropertyIcon from 'mdi-react/BankIcon';

import SessionExpireModal from '../../SessionExpire/sessionExpireModal';

import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

class Example extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            notifs: [],
            isOpenSessionExpiredModal: false,
        }
    }

    componentDidMount = async () => {
        await this.getNotifs();
        window.addEventListener('keydown', (e) => {
            if (this.state.isOpen) {
                if ((e.key === 'Escape' || e.key === 'Esc')) {
                    e.preventDefault();
                    this.toggle();
                    return false;
                }
            }
        });

    }

    getNotifs = async () => {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        let email = user.email;
        let filterCriteria = {
            criteria: [],
            sortfield: 'created',
            direction: 'desc'
        };
        filterCriteria['criteria'].push(
            { key: 'userEmail', value: email, type: 'eq' }
        )
        let filterString = JSON.stringify(filterCriteria);
        let url = `notifications?filter=${filterString}`;
        let method = 'GET';

        fetchMethodRequest(method, url).then(async res => {
            if (res && res.respCode) {
                await this.setState({
                    notifs: res.notifications,
                    isLoading: false,
                })
            } else {
                if (res && res.errorMessage && res.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                        isOpen: false,
                        isLoading: false,
                    })
                } else if (res && res.errorMessage) {
                    this.setState({
                        isLoading: false,
                    });
                    showToasterMessage(res.errorMessage, 'error');
                } else {
                    this.setState({
                        isLoading: false,
                    });
                }
            }
        })
    }

    toggle = async () => {
        this.setState({
            isOpen: !this.state.isOpen,
        });
        if (this.state.isOpen) { } else {
            await this.getNotifs();
        }
    }
    render() {
        const { tl } = this.props;
        const { isOpen, notifs } = this.state;
        return (
            <div className='topBarNotifIcon pr-sm-0 pr-md-2'>
                <span
                    onClick={this.toggle}
                >
                    <BellIcon className='notifBellIcon' />
                    {notifs && notifs.length > 0 ?
                        <div className='notifCount'>
                            {notifs.length > 9 ? '9+' : notifs.length}
                        </div>
                        : null
                    }
                </span>
                {/* {isOpen && <button className="topbar__back" type="button" onClick={this.toggle} />} */}
                <Collapse
                    isOpen={isOpen}
                    className='notifCollapse'
                >
                    <Card className='pb-0'>
                        <CardBody className='justify-content-center p-0'>
                            <h4 className='text-center mt-2 mx-4'>
                                {tl['notifications'] ? tl['notifications'] : 'Notifications'}
                            </h4>
                            <Button
                                className='modalToggleButton'
                                color="primary"
                                close={true}
                                onClick={() => this.toggle()}
                            />
                            <ScrollBar className='notifListScroll'>
                                {notifs && notifs.length > 0 ?
                                    notifs.map((item, index) => {
                                        return (
                                            <div className='row notifItem mx-4' key={`notif${index}`}>
                                                <div className="col-auto px-0 notifItemIcon">
                                                    <PropertyIcon />
                                                </div>
                                                <div className="col pr-0">
                                                    <p>
                                                        {item.body}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })
                                    : <div className='row notifItem mx-4'>
                                        <p className='col text-center'> {tl['noNotifications'] ? tl['noNotifications'] : 'No Notifications'}</p>
                                    </div>
                                }
                            </ScrollBar>
                        </CardBody>
                    </Card>
                </Collapse>

                {/* #Session Expire Modal */}
                {this.state.isOpenSessionExpiredModal ?
                    <SessionExpireModal
                        isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                        tl={tl}
                    />
                    : null
                }
            </div>
        );
    }
}

export default Example;
import config from '../../config/config';
import configMessages from '../../config/configMessages';
import dateFormats from '../UI/FormatDate/formatDate';

const validate = (values, props) => {
  let fields = [];
  // get formFields from props and set validation  fields
  if (props.formFields) {
    fields = [];
    let formFields = props.formFields();
    formFields.forEach(formField => {
      if (formField.required) {
        fields.push(formField.name);
      }
    });
  }
  const errors = {};


  let checkRegExpFieldsArray = ['aadharcardNumber', 'pancardNumber', 'email', 'companyEmail', 'phoneNumber', 'password'];

  fields.forEach((field) => {
    if (!values[field]) {
      errors[field] = configMessages.fillField;
    } else if (checkRegExpFieldsArray.includes(field)) {
      let regex = config[field + "Regex"];
      if (field === 'companyEmail') {
        regex = config['emailRegex'];
      }
      if (values[field] && typeof (values[field]) === 'string' && regex.test(values[field].trim()) === false) {
        if (field === 'companyEmail') {
          errors[field] = configMessages['email'];
        } else {
          errors[field] = configMessages[field];
        }
      }
    }
  });

  if (values.email) {
    let regex = config["emailRegex"];
    if (regex.test(values.email)) {
    } else {
      errors.email = configMessages['email']
    }
  }

  if (values.phoneNumber) {
    let regex = config["phoneNumberRegex"];
    if (regex.test(values.phoneNumber)) {
    } else {
      errors.phoneNumber = configMessages['phoneNumber']
    }
  }

  if (values.otp) {
    let regex = /^[0-9]{6}$/;
    if (regex.test(values.otp)) {
    } else {
      errors.otp = 'Enter valid OTP'
    }
  }

  if (values.RERANumber) {
    let regex = config["RERANumberRegex"];
    if (regex.test(values.RERANumber)) {
    } else {
      errors.RERANumber = configMessages['RERANumber']
    }
  }

  let todayDate = dateFormats.formatDate(new Date(), config.dateDashFormat);
  let compare;
  if (values.dateOfBirth) {
    compare = dateFormats.datesComparisionBefore(todayDate, values.dateOfBirth);
    if (compare) {
      errors.dateOfBirth = configMessages.InvalidDate;
    }
  }

  if (values.endDate && values.startDate) {
    compare = dateFormats.datesComparisionBefore(values.endDate, values.startDate);
    if (compare) {
      errors['endDate'] = configMessages.InvalidDate;
    } else {
      errors['endDate'] = '';
    }
  }
  if (values.toDate && values.fromDate) {
    compare = dateFormats.datesComparisionBefore(values.toDate, values.fromDate);
    if (compare) {
      errors['toDate'] = configMessages.InvalidDate;
    } else {
      errors['toDate'] = '';
    }
  }
  if (values.joinDate) {
    if (values.dateOfBirth) {
      let years = dateFormats.differenceBetweenDatesInYears(values.joinDate, values.dateOfBirth);
      if (years < 15) {
        errors.joinDate = configMessages.InvalidDate;
      } else {
        errors.joinDate = '';
      }
    }
  }
  if (values.password && values.confirmPassword) {
    if (values.confirmPassword !== values.password) {
      errors.confirmPassword = 'Passwords not matched';
    }
  }

  if (values.newPassword && values.confirmPassword) {
    if (values.confirmPassword !== values.newPassword) {
      errors.confirmPassword = 'Passwords not matched';
    }
  }

  if (props.form === 'searchProps') {

    if (values.totalPriceMin) {
      let numRegEx = /^[0-9][0-9]*$/;
      let c = values.totalPriceMin;
      c = c.toString();
      if (c[0] === ',') {
        errors.totalPriceMin = 'Enter Valid Number'
      } else {
        c = c.replace(/,/g, '');
        let isNumber = numRegEx.test(c);
        if (isNumber) {
          c = c.replace(/,/g, '');
          let num = parseInt(c);
          let inputVal = (num).toLocaleString('en-IN')
          values.totalPriceMin = inputVal
        } else {
          errors.totalPriceMin = 'Enter Valid Number'
        }
      }
    }

    if (values.totalPriceMax) {
      let numRegEx = /^[1-9][0-9]*$/;
      let c = values.totalPriceMax;
      c = c.toString();
      if (c[0] === ',') {
        errors.totalPriceMax = 'Enter Valid Number'
      } else {
        c = c.replace(/,/g, '');
        let isNumber = numRegEx.test(c);
        if (isNumber) {
          c = c.replace(/,/g, '');
          let num = parseInt(c);
          let inputVal = (num).toLocaleString('en-IN')
          values.totalPriceMax = inputVal
        } else {
          errors.totalPriceMax = 'Enter Valid Number'
        }
      }
    }

    if (values.builtUpAreaMin) {
      let numRegEx = /^[0-9][0-9]*$/;
      let c = values.builtUpAreaMin;
      c = c.toString();
      if (c[0] === ',') {
        errors.builtUpAreaMin = 'Enter Valid Number'
      } else {
        c = c.replace(/,/g, '');
        let isNumber = numRegEx.test(c);
        if (isNumber) {
          c = c.replace(/,/g, '');
          let num = parseInt(c);
          let inputVal = (num).toLocaleString('en-IN')
          values.builtUpAreaMin = inputVal
        } else {
          errors.builtUpAreaMin = 'Enter Valid Number'
        }
      }
    }

    if (values.builtUpAreaMax) {
      let numRegEx = /^[1-9][0-9]*$/;
      let c = values.builtUpAreaMax;
      c = c.toString();
      if (c[0] === ',') {
        errors.builtUpAreaMax = 'Enter Valid Number'
      } else {
        c = c.replace(/,/g, '');
        let isNumber = numRegEx.test(c);
        if (isNumber) {
          c = c.replace(/,/g, '');
          let num = parseInt(c);
          let inputVal = (num).toLocaleString('en-IN')
          values.builtUpAreaMax = inputVal
        } else {
          errors.builtUpAreaMax = 'Enter Valid Number'
        }
      }
    }

    if (values.propertyType && (values.propertyType === '1RK' || values.propertyType === 'Commercial')) {
      values.bedRooms = null
    }
  }

  if (props.form === 'addPropertyForm') {

    if (values.totalPrice) {
      let numRegEx = /^[1-9][0-9]*$/;
      let c = values.totalPrice;
      c = c.toString();
      if (c[0] === ',') {
        errors.totalPrice = 'Enter Valid Number'
      } else {
        c = c.replace(/,/g, '');
        let isNumber = numRegEx.test(c);
        if (isNumber) {
          c = c.replace(/,/g, '');
          let num = parseInt(c);
          let inputVal = (num).toLocaleString('en-IN')
          values.totalPrice = inputVal
        } else {
          errors.totalPrice = 'Enter Valid Number'
        }
      }
    }
    if (values.rent) {
      let numRegEx = /^[1-9][0-9]*$/;
      let c = values.rent;
      c = c.toString();
      if (c[0] === ',') {
        errors.rent = 'Enter Valid Number'
      } else {
        c = c.replace(/,/g, '');
        let isNumber = numRegEx.test(c);
        if (isNumber) {
          c = c.replace(/,/g, '');
          let num = parseInt(c);
          let inputVal = (num).toLocaleString('en-IN')
          values.rent = inputVal
        } else {
          errors.rent = 'Enter Valid Number'
        }
      }
    }
    if (values.deposit) {
      let numRegEx = /^[1-9][0-9]*$/;
      let c = values.deposit;
      c = c.toString();
      if (c[0] === ',') {
        errors.deposit = 'Enter Valid Number'
      } else {
        c = c.replace(/,/g, '');
        let isNumber = numRegEx.test(c);
        if (isNumber) {
          c = c.replace(/,/g, '');
          let num = parseInt(c);
          let inputVal = (num).toLocaleString('en-IN')
          values.deposit = inputVal
        } else {
          errors.deposit = 'Enter Valid Number'
        }
      }
    }
    if (values.plotArea) {
      let numRegEx = /^[1-9][0-9]*$/;
      let c = values.plotArea;
      c = c.toString();
      if (c[0] === ',') {
        errors.plotArea = 'Enter Valid Number'
      } else {
        c = c.replace(/,/g, '');
        let isNumber = numRegEx.test(c);
        if (isNumber) {
          c = c.replace(/,/g, '');
          let num = parseInt(c);
          let inputVal = (num).toLocaleString('en-IN')
          values.plotArea = inputVal
        } else {
          errors.plotArea = 'Enter Valid Number'
        }
      }
    }
    if (values.builtUpArea) {
      let numRegEx = /^[1-9][0-9]*$/;
      let c = values.builtUpArea;
      c = c.toString();
      if (c[0] === ',') {
        errors.builtUpArea = 'Enter Valid Number'
      } else {
        c = c.replace(/,/g, '');
        let isNumber = numRegEx.test(c);
        if (isNumber) {
          c = c.replace(/,/g, '');
          let num = parseInt(c);
          let inputVal = (num).toLocaleString('en-IN')
          values.builtUpArea = inputVal
        } else {
          errors.builtUpArea = 'Enter Valid Number'
        }
      }
    }
    if (values.carpetArea) {
      let numRegEx = /^[1-9][0-9]*$/;
      let c = values.carpetArea;
      c = c.toString();
      if (c[0] === ',') {
        errors.carpetArea = 'Enter Valid Number'
      } else {
        c = c.replace(/,/g, '');
        let isNumber = numRegEx.test(c);
        if (isNumber) {
          c = c.replace(/,/g, '');
          let num = parseInt(c);
          let inputVal = (num).toLocaleString('en-IN')
          values.carpetArea = inputVal
        } else {
          errors.carpetArea = 'Enter Valid Number'
        }
      }
    }

    if (values.noOfFloors) {
      let numRegEx = /^[1-9][0-9]*$/;
      let c = values.noOfFloors;
      c = c.toString();
      if (c[0] === ',') {
        errors.noOfFloors = 'Enter Valid Number'
      } else {
        c = c.replace(/,/g, '');
        let isNumber = numRegEx.test(c);
        if (isNumber) {
          c = c.replace(/,/g, '');
          let num = parseInt(c);
          let inputVal = (num).toLocaleString('en-IN')
          values.noOfFloors = inputVal
        } else {
          errors.noOfFloors = 'Enter Valid Number'
        }
      }
    }
  }

  if (props.form === 'ChangePasswordForm') {
    if (!values.currentPassword) {
      errors.currentPassword = 'Current Password is required'
    } else if (!values.newPassword) {
      errors.newPassword = 'New Password is required'
    } else if (!values.confirmPassword) {
      errors.confirmPassword = 'Confirm Password is required'
    } else if (values.newPassword && values.confirmPassword) {
      if (values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = 'Passwords not matched';
      }
    }
  }

  return errors;
};


export default validate;


import React, { PureComponent } from 'react';
import RegistrationForm from './components/RegistrationForm';
import config from '../../config/config';

class Register extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lang: (localStorage.getItem('lang') && localStorage.getItem('lang') !== 'undefined' ? localStorage.getItem('lang') : null),
      tl: {}
    }
  }

  componentDidMount = async () => {
    let tl = {}
    if (this.props.location && this.props.location.state && this.props.location.state.tl) {
      tl = this.props.location.state.tl
    } else {
      tl = JSON.parse(localStorage.getItem('langJson'));
    }
    await this.setState({
      tl: tl,
    })
  }

  render() {
    const { tl } = this.state;
    return (
      <div className="account">
        <div className="account__wrapper">
          <div className="card bgWhite p-5">
            <div className="text-center mb-2">
              <h3 className="account__title">
                {tl['welcomeTo'] ? tl['welcomeTo'] + ' ' : 'Welcome to '}
                <span className="account__logo">
                  <span className="account__logo-accent" >
                    {tl[config.projectName] ? tl[config.projectName] : 'Project Name'}
                  </span>
                </span>
              </h3>
            </div>
            <RegistrationForm onSubmit tl={tl} />
          </div>
        </div>
      </div>
    );
  }
}

export default Register;

import React from "react";
import { Modal } from "reactstrap";

//config
import config from '../../../config/config';
import configImages from "../../../config/configImages";

class ViewPhotosModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayPhoto: '',
            index: 0
        }
    }

    componentDidMount = () => {
        this.updateDisplayPhoto(0);
    }

    updateDisplayPhoto = (index) => {
        const { propertyPhotos } = this.props;
        if (propertyPhotos && propertyPhotos.length > 0) {
            const photo = typeof propertyPhotos[index] === 'object' ? propertyPhotos[index].name : propertyPhotos[index];
            const displayPhoto = `${config.imgUrl}myproperties/${photo}`;
            this.setState({ displayPhoto, index });
        }
    }

    // Changing images when click on left and right arrows
    changeImageWhenClickOnIcons = (ind) => {
        const { propertyPhotos } = this.props;
        let index = ind || ind === 0 ? ind : this.state.index;

        if (propertyPhotos && propertyPhotos.length > 0) {
            if (ind === -1) {
                index = propertyPhotos.length - 1;
            } else if (ind === propertyPhotos.length) {
                index = 0;
            }
            if (index <= propertyPhotos.length - 1 && index >= 0) {
                this.updateDisplayPhoto(index);
            }
        }
    }

    render() {
        const { displayPhoto, index } = this.state;
        const { propertyPhotos } = this.props;


        return (
            <Modal
                isOpen={this.props.isViewPhotosModalOpen}
                toggle={() => this.props.closeViewPhotosModal('close')}
                className='photoViewModal modal-dialog-centered'
                style={{ maxWidth: '100%', background: config.modalBackground, height: '100%', margin: '0px' }}
            >
                <div className='photosModalCloseIcon'>
                    <img
                        src={configImages.closeIcon}
                        alt='closeIcon'
                        className='closeIcon'
                        onClick={() => this.props.closeViewPhotosModal('close')}
                    />
                </div>
                <div className="content-between">
                    <div className='sliderNext'>
                        <div className='slider-left' onClick={() => this.changeImageWhenClickOnIcons(index - 1)} />
                    </div>
                    <div className='col'>
                        <img
                            src={displayPhoto}
                            className='image-width'
                            alt=''
                        />
                        <div className='imagesCount'>{index + 1} / {propertyPhotos.length}</div>
                        <div className='container horizontal_scroll'>
                            <div className='text-center'>
                                {propertyPhotos.map((item, ind) => {
                                    const photo = typeof item === 'object' ? item.name : item;
                                    return (
                                        <img
                                            key={ind}
                                            style={index === ind ? { opacity: 1 } : { opacity: 0.3 }}
                                            src={`${config.imgUrl}myproperties/${photo}`}
                                            className='previewImg'
                                            alt=''
                                            onClick={() => this.changeImageWhenClickOnIcons(ind)}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className='sliderNext'>
                        <div className='slider-right' onClick={() => this.changeImageWhenClickOnIcons(index + 1)} />
                    </div>
                </div>
            </Modal>
        );
    }
}
export default ViewPhotosModal
import React, { PureComponent } from 'react';
import { Row, Button } from 'reactstrap';
import ScrollBar from 'react-smooth-scrollbar';
import { Redirect } from 'react-router-dom';

import { Field, reduxForm } from 'redux-form';
import { load as loadAccount } from '../../../redux/reducers/commonReducer';
import { connect } from 'react-redux';

import DefaultInput from '../../../shared/components/form/DefaultInput';
import RadioButton from '../../../shared/components/form/RadioButton';
import Select from '../../../shared/components/form/Select';
import DefaultTextArea from '../../../shared/components/form/DefaultTextArea';
import DropZone from '../../../shared/components/form/DropZoneMultiple'

import Loader from '../../App/Loader';
import fetchMethodRequest from '../../../config/service';
import configMessages from '../../../config/configMessages';
import config from '../../../config/config';
import OtpVerifyModal from './OtpVerifyModal';

import CloseIcon from 'mdi-react/CloseCircleIcon';

// Validate 
import validate from '../../Validations/validate';
import showToasterMessage from '../../UI/ToasterMessage/toasterMessage';

import SessionExpireModal from '../../SessionExpire/sessionExpireModal';

const required = value => (value || typeof value === 'string' ? undefined : configMessages.fillField)

class AddPropertiesScreen extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            redirectToMyProperties: false,
            isPropertyCreationSuccess: false,
            isOpenClientsFormModal: false,
            tl: {},
            toDeletePhotos: [],
            isOpenOtpVerifyModal: false,
        };
    }

    componentDidMount = async () => {
        let tl = {};
        let loc = this.props.location;
        let isOpenOtpVerifyModal = false;
        let user = await JSON.parse(localStorage.getItem('loginCredentials'));
        if (user && user.isOtpVerified === false) {
            isOpenOtpVerifyModal = true;
        }
        if (loc && loc.state && loc.state.tl) {
            tl = loc.state.tl
        } else {
            tl = JSON.parse(localStorage.getItem('langJson'));
        }

        let id = this.props.match.params.id;
        if (id) {
            await this.loadEditData();
        } else {
            await this.setState({
                isLoading: false,
            })
        }

        if (loc.state && loc.state.fromScreen !== '/matchedPropertiesList') {
            localStorage.setItem('myClientCriteria', JSON.stringify(loc.state.fromCriteria))
            localStorage.setItem('myFilterCriteria', JSON.stringify(loc.state.currentFilterCriteria))
            localStorage.setItem('fromScreen', JSON.stringify(loc.state.fromScreen))
        }
        await this.setState({
            tl: tl,
            isOpenOtpVerifyModal: isOpenOtpVerifyModal,
        })
    }

    // Close Otp/Phone verify Modal
    closeOtpVerifyModal = async (type) => {
        if (type) {
            await this.setState({
                isOpenOtpVerifyModal: false,
                phoneNumber: type
            })
        } else {
            await this.setState({
                isOpenOtpVerifyModal: false,
                redirectToMyProperties: true,
            })
        }
    }

    // Get Property Data and load to form fields to Edit/Update
    loadEditData = async () => {
        let id = this.props.match.params.id;
        let rowData = this.props.location.state.editData;

        if (rowData['propertyFor'] === 'Rent') {
            rowData['rent'] = rowData['totalPrice']
        }

        if (id && rowData) {
            await this.setState({
                propertyId: id,
                rowData: rowData,
                formType: 'edit',
                isLoading: false,
            })
        } else {
            await this.setState({
                isLoading: false,
            })
        }

        if (this.props.load) {
            this.props.load(rowData);
        }
    }

    // Open Add Client Modal
    openAddClientModal = async () => {
        await this.setState({
            isOpenClientsFormModal: true,
        })
    }

    // Handler to  Redirect to Previus Properties Screen
    redirectToMyProperties = async () => {
        await this.setState({
            redirectToMyProperties: true,
        })
    }

    // Handler triggered when submit/update button clicked
    submit = async (values) => {
        await this.validate(values).then(async resp => {
            if (resp) {
                if (values.rent) {
                    values.rent = values.rent.replace(/,/g, '');
                }
                if (values.deposit) {
                    values.deposit = values.deposit.replace(/,/g, '');
                }
                if (values.totalPrice) {
                    values.totalPrice = values.totalPrice.replace(/,/g, '');
                }
                if (values.plotArea) {
                    values.plotArea = values.plotArea.replace(/,/g, '');
                }
                if (values.builtUpArea) {
                    values.builtUpArea = values.builtUpArea.replace(/,/g, '');
                }
                if (values.carpetArea) {
                    values.carpetArea = values.carpetArea.replace(/,/g, '');
                }
                if (values.noOfFloors) {
                    values.noOfFloors = values.noOfFloors.replace(/,/g, '');
                }
                if (values.plotArea) {
                    values['builtUpArea'] = values.plotArea
                }
                if (values.propertyFor === 'Rent') {
                    if (values.rent) {
                        values['totalPrice'] = values.rent
                    }
                } else {
                    values['rent'] = 0;
                    values['deposit'] = 0;
                    values['monthlyMaintenance'] = 0;
                }
                await this.savePropertyToServer(values);
                await this.props.reset();
                await window.scrollTo(0, 0);
            } else {
                showToasterMessage('Invalid Data provided, please check again');
                await window.scrollTo(0, 0);
            }
        })
    }

    // Form Fields Validation Handler
    validate = async (values) => {
        let propertyForError = '';
        let propertyTypeError = '';
        let availabilityError = '';
        let furnishingTypeError = '';
        let facingError = '';
        let bedRoomsError = '';
        let isBedRoomsValid;

        if (
            values.propertyFor && values.propertyType &&
            values.availability && values.furnishingType &&
            values.facing
        ) {
            if (values.propertyType === '1RK' || values.propertyType === 'Commercial') {
                isBedRoomsValid = true;
            } else {
                if (values.bedRooms) {
                    isBedRoomsValid = true;
                } else {
                    bedRoomsError = 'thisFieldIsReq'
                    isBedRoomsValid = false;
                }
            }

            if (isBedRoomsValid) {
                return true;
            } else {
                await this.setState({
                    propertyForError: propertyForError,
                    propertyTypeError: propertyTypeError,
                    availabilityError: availabilityError,
                    furnishingTypeError: furnishingTypeError,
                    facingError: facingError,
                    bedRoomsError: bedRoomsError,
                })
                return false;
            }
        } else {
            if (!values.propertyFor) {
                propertyForError = 'thisFieldIsReq'
            }
            if (!values.propertyType) {
                propertyTypeError = 'thisFieldIsReq'
            }
            if (!values.facing) {
                facingError = 'thisFieldIsReq'
            }
            if (!values.availability) {
                availabilityError = 'thisFieldIsReq'
            }
            if (!values.furnishingType) {
                furnishingTypeError = 'thisFieldIsReq'
            }
            await this.setState({
                propertyForError: propertyForError,
                propertyTypeError: propertyTypeError,
                availabilityError: availabilityError,
                furnishingTypeError: furnishingTypeError,
                facingError: facingError,
                bedRoomsError: bedRoomsError,
            })
            window.scrollTo(0, 0);
            return false;
        }
    }

    // Handle newly selected photos before sending data to server
    handleSelectedPhotos = async () => {
        let picFiles = this.state.currentPhotoFiles;
        if (picFiles && picFiles.length > 0) {
            await this.setState({ newPicsToUpload: picFiles });
        } else if (picFiles && picFiles === []) {
            await this.setState({ newPicsToUpload: null });
        } else {
            await this.setState({ newPicsToUpload: null });
        }
    }

    // Send data to server upon successful validation of form fields
    savePropertyToServer = async (values) => {
        let url = 'properties?response=true';
        let method = 'POST';
        let user = await JSON.parse(localStorage.getItem('loginCredentials'));

        await this.handleSelectedPhotos();
        if (this.state.formType && this.state.formType === 'edit') {
            url = `properties/${this.state.propertyId}`;
            method = 'PUT';
            values['photo'] = this.state.rowData.photo;
        } else {
            values['email'] = user.email;
            values['customerId'] = user._id;
        }
        await this.setState({
            isLoading: true,
        });

        delete values['newPhotos'];
        fetchMethodRequest(method, url, values).then(async res => {
            if (res && res.respCode) {
                if (res.propertyId && (this.state.newPicsToUpload || (this.state.toDeletePhotos && this.state.toDeletePhotos.length > 0))) {
                    await this.UploadFileToServer(res.propertyId, res.respMessage);
                } else {
                    showToasterMessage(res.respMessage, 'success')
                    await this.setState({
                        isLoading: false,
                        isPropertyCreationSuccess: true,
                    });
                }
            } else {
                if (res.errorMessage && res.errorMessage === 'Session expired please login again.') {
                    await this.setState({
                        isOpenSessionExpiredModal: true,
                    })
                } else {
                    showToasterMessage(res.errorMessage, 'error')
                    this.setState({
                        isLoading: false,
                    });
                }
            }
        }).catch(err => {
            showToasterMessage(err, 'error')
            return err
        })
    }

    // Image Upload Handler after submit/update clicked
    UploadFileToServer = async (propId, successMsg) => {
        let url;
        let removedPicsArray = [];
        let rpaString = '';
        if (this.state.newPicsToUpload && (this.state.toDeletePhotos && this.state.toDeletePhotos.length > 0)) {
            this.state.toDeletePhotos.forEach(item => {
                removedPicsArray.push(item.name);
            })
            rpaString = JSON.stringify(removedPicsArray);
            url = `uploads?uploadPath=property&entityType=property&_id=${propId}&photo=${rpaString}`;
        } else if (this.state.toDeletePhotos && this.state.toDeletePhotos.length > 0) {
            this.state.toDeletePhotos.forEach(item => {
                removedPicsArray.push(item.name);
            })
            rpaString = JSON.stringify(removedPicsArray);
            url = `uploads?uploadPath=property&entityType=property&_id=${propId}&photo=${rpaString}&onlyDelete=yes`;
        } else if (this.state.newPicsToUpload) {
            url = `uploads?uploadPath=property&entityType=property&_id=${propId}`;
        } else {
            url = null;
        }
        if (url) {
            fetchMethodRequest('POST', url, this.state.newPicsToUpload, 'upload').then(async (response) => {
                if (response && response.respCode) {
                    showToasterMessage(successMsg, 'success');
                    await this.setState({
                        isLoading: false,
                        isPropertyCreationSuccess: true,
                    });
                } else {
                    if (response.errorMessage && response.errorMessage === 'Session expired please login again.') {
                        await this.setState({
                            isOpenSessionExpiredModal: true,
                        })
                    } else {
                        showToasterMessage(response.errorMessage, 'error');
                        await this.setState({
                            isLoading: false,
                            isPropertyCreationSuccess: true,
                        });
                    }
                }
            }).catch((err) => {
                return err;
            });
        } else {
            return;
        }
    }

    // Handles state of which previus photos to be removed
    removePhotoFromProp = async (prop, photo) => {
        let propData = prop;
        let photos = prop.photo
        let picToRemove = photo.name;

        let filteredPhotos = photos.filter((item) => item.name !== picToRemove);
        let removedPhotos = photos.filter((item) => item.name === picToRemove);

        let toDeletePhotos = [...this.state.toDeletePhotos, ...removedPhotos];


        propData.photo = filteredPhotos;

        await this.setState({
            rowData: propData,
            toDeletePhotos: toDeletePhotos,
        })
    }

    // Field Onchange Handler
    handleChange = async (name, val) => {
        if (name === 'propertyFor' && this.state.propertyForError) {
            await this.setState({
                [`${name}`]: val,
                propertyForError: '',
            })
        } else if (name === 'propertyType' && this.state.propertyTypeError) {
            await this.setState({
                [`${name}`]: val,
                propertyTypeError: '',
            })
        } else if (name === 'availability' && this.state.availabilityError) {
            await this.setState({
                [`${name}`]: val,
                availabilityError: '',
            })
        } else if (name === 'furnishingType' && this.state.furnishingTypeError) {
            await this.setState({
                [`${name}`]: val,
                furnishingTypeError: '',
            })
        } else if (name === 'facing' && this.state.facingError) {
            await this.setState({
                [`${name}`]: val,
                facingError: '',
            })
        } else if (name === 'bedRooms' && this.state.bedRoomsError) {
            await this.setState({
                [`${name}`]: val,
                bedRoomsError: '',
            })
        } else {
            await this.setState({
                [`${name}`]: val,
            })
        }
    }

    // CLient Field, Selection handler
    handleSelectedOption = async (option) => {
        await this.setState({
            client: option
        })
    }

    // Photo Upload event handler
    handleCurrentSelectedPhotos = async (photoFiles) => {
        await this.setState({
            currentPhotoFiles: photoFiles,
        })
        return photoFiles;
    }

    // To Remove all newly selected photos
    removeAllSelectedPhotos = async () => {
        if (this.DropZoneRef) {
            await this.DropZoneRef.removeAllFiles()
        }
    }

    // Validate Requirement Query Input Handler
    validateReq = async () => {
        let query = this.state.validateReq;
        if (query) {
            query = query.toLowerCase();
            query = query.replaceAll('\n', ",")
            let qwueryKeyWords = query.split(',');

            if (query.includes('sale')) {
                await this.props.change('propertyFor', 'Sale');
                await this.handleChange('propertyFor', 'Sale');
            } else if (query.includes('rent')) {
                await this.props.change('propertyFor', 'Rent')
                await this.handleChange('propertyFor', 'Rent')
            }

            for (let req of qwueryKeyWords) {
                if (req && (req.includes('rs') || req.includes('/-'))) {
                    let budget = null
                    if (req.includes('rs')) {
                        budget = req.replace('rs', '');
                    } else if (req.includes('/-')) {
                        budget = req.replace('/-', '');
                    }
                    budget = budget.replaceAll(/\s/g, '');
                    if (budget && budget.includes('crores')) {
                        budget = budget.replace('crores', '');
                        budget = budget + '0000000';
                    } else if (budget && budget.includes('lakhs')) {
                        budget = budget.replace('lakhs', '');
                        budget = budget + '00000';
                    } else if (budget && budget.includes('k')) {
                        budget = budget.replace('k', '');
                        budget = budget + '000';
                    }

                    if (budget && this.state.propertyFor) {
                        if (this.state.propertyFor === 'Sale') {
                            this.props.change('totalPrice', budget)
                        } else if (this.state.propertyFor === 'Rent') {
                            this.props.change('rent', budget)
                        }
                    }
                    break;
                }
            }

            if (query.includes('1rk')) {
                await this.props.change('propertyType', '1RK')
                await this.handleChange('propertyType', '1RK')
            } else if (query.includes('flat')) {
                await this.props.change('propertyType', 'Flat')
                await this.handleChange('propertyType', 'Flat')
            } else if (query.includes('bungalow')) {
                await this.props.change('propertyType', 'Bungalow')
                await this.handleChange('propertyType', 'Bungalow')
            } else if (query.includes('commercial')) {
                await this.props.change('propertyType', 'Commercial')
                await this.handleChange('propertyType', 'Commercial')
            } else if (query.includes('duplex')) {
                await this.props.change('propertyType', 'Duplex')
                await this.handleChange('propertyType', 'Duplex')
            }

            for (let req of qwueryKeyWords) {
                if (req && req.includes('bhk')) {
                    let bedRooms = req.replace('bhk', '');
                    bedRooms = bedRooms.replaceAll(/\s/g, '');
                    if (parseInt(bedRooms)) {
                        if (bedRooms && this.state.propertyFor) {
                            if (!(this.state.propertyType === 'Commercial' || this.state.propertyType === '1RK')) {
                                if (parseInt(bedRooms) > 4) {
                                    bedRooms = '4';
                                }
                                this.props.change('bedRooms', bedRooms)
                            }
                        }
                    } else {
                        showToasterMessage('Unable to validate', 'error');
                        this.props.load({})
                    }
                    break;
                }
            }

            if (query.includes('unfurnished')) {
                this.props.change('furnishingType', 'Un-Furnished')
            } else if (query.includes('furnished')) {
                this.props.change('furnishingType', 'Furnished')
            } else if (query.includes('rent')) {
                this.props.change('semifurnished', 'Semi-Furnished')
            }

            if (query.includes('ready to move')) {
                this.props.change('availability', 'Ready to Move')
            } else if (query.includes('under construction')) {
                this.props.change('availability', 'Under Construction')
            }

            if (query.includes('north east')) {
                this.props.change('facing', 'North East')
            } else if (query.includes('north west')) {
                this.props.change('facing', 'North West')
            } else if (query.includes('south east')) {
                this.props.change('facing', 'South East')
            } else if (query.includes('south west')) {
                this.props.change('facing', 'South West')
            } else if (query.includes('north')) {
                this.props.change('facing', 'North')
            } else if (query.includes('south')) {
                this.props.change('facing', 'South')
            } else if (query.includes('east')) {
                this.props.change('facing', 'East')
            } else if (query.includes('west')) {
                this.props.change('facing', 'West')
            }

        } else {
            showToasterMessage('Invalid query', 'warning')
        }
    }

    render() {
        const { tl, rowData, formType } = this.state;
        const { handleSubmit } = this.props;
        return (
            <div className='addPropertyScreen px-3'>
                <ScrollBar className='addPropertyScrollbar'>
                    <Loader loader={this.state.isLoading} />
                    {/* Page Header */}
                    <Row className='align-items-center mx-0 mb-3'>
                        {/* <div className="col-4 text-left">
                            <BackIcon
                                className='backIcon cursorPointer'
                                onClick={() => this.redirectToMyProperties()}
                            />
                        </div> */}
                        <div className='col pageHeadingCss text-center'>
                            {tl['addProperty'] ? tl['addProperty'] : 'ADD PROPERTY'}
                        </div>
                    </Row>

                    <div className="form form__form-group col-md-6 col-sm-8">
                        <span className="form__form-group-label">
                            {tl['validateReq'] ? tl['validateReq'] : 'Validate Requirement'}
                        </span>
                        <div className="form__form-group-field">
                            <Field
                                className='textAreaPh'
                                name='validateReq'
                                component={DefaultTextArea}
                                placeholder={tl['enterSearchReqText'] ? tl['enterSearchReqText'] : 'Enter your property requirement to validate'}
                                onChange={(e) => this.handleChange('validateReq', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="form form__form-group col-md-6 col-sm-4">
                        <Button
                            type='button'
                            className="mr-3"
                            color="primary"
                            onClick={this.validateReq}
                            disabled={this.state.validateReq ? false : true}
                        >
                            {
                                tl['validate'] ? tl['validate'] : 'Validate'
                            }
                        </Button>
                    </div>

                    {/* Property Add/Edit Form starts here */}
                    <Row className='justify-content-center mx-0'>
                        <form
                            className="form"
                            onSubmit={handleSubmit(this.submit)}
                            style={{ width: '100% !important' }}
                        >
                            <div className="form__form-group col-md-6">
                                <span className="form__form-group-label">
                                    {tl['propertyFor'] ? tl['propertyFor'] : 'Property For'} *
                                            </span>
                                <div className="form__form-group-field row mx-0">
                                    <Field
                                        className='col'
                                        name={'propertyFor'}
                                        component={RadioButton}
                                        label={tl && tl['sale'] ? tl['sale'] : 'Sale'}
                                        radioValue={'Sale'}
                                        defaultChecked={false}
                                        onChange={(val) => this.handleChange('propertyFor', val)}
                                    />
                                    <Field
                                        className='col'
                                        name={'propertyFor'}
                                        component={RadioButton}
                                        label={tl['rent'] ? tl['rent'] : 'Rent'}
                                        radioValue={'Rent'}
                                        defaultChecked={false}
                                        onChange={(val) => this.handleChange('propertyFor', val)}
                                    />
                                </div>
                                {this.state.propertyForError ?
                                    <span className="form__form-group-error">
                                        {this.state.propertyForError ?
                                            tl[this.state.propertyForError] ? tl[this.state.propertyForError] : 'This field is required'
                                            : ''
                                        }
                                    </span>
                                    : null
                                }
                            </div>
                            <div className="form__form-group col-md-6">
                                <span className="form__form-group-label">
                                    {tl['propertyType'] ? tl['propertyType'] : 'Property Type'} *
                                            </span>
                                <div className="form__form-group-field row mx-0">
                                    <Field
                                        className='col'
                                        name={'propertyType'}
                                        component={RadioButton}
                                        label={tl['1rk'] ? tl['1rk'] : '1RK'}
                                        radioValue={'1RK'}
                                        defaultChecked={false}
                                        onChange={(val) => this.handleChange('propertyType', val)}
                                    />
                                    <Field
                                        className='col'
                                        name={'propertyType'}
                                        component={RadioButton}
                                        label={tl['flat'] ? tl['flat'] : 'Flat'}
                                        radioValue={'Flat'}
                                        defaultChecked={false}
                                        onChange={(val) => this.handleChange('propertyType', val)}
                                    />
                                    <Field
                                        className='col'
                                        name={'propertyType'}
                                        component={RadioButton}
                                        label={tl['duplex'] ? tl['duplex'] : 'Duplex'}
                                        radioValue={'Duplex'}
                                        defaultChecked={false}
                                        onChange={(val) => this.handleChange('propertyType', val)}
                                    />
                                    <Field
                                        className='col'
                                        name={'propertyType'}
                                        component={RadioButton}
                                        label={tl['bungalow'] ? tl['bungalow'] : 'Bungalow'}
                                        radioValue={'Bungalow'}
                                        defaultChecked={false}
                                        onChange={(val) => this.handleChange('propertyType', val)}
                                    />
                                    <Field
                                        className='col'
                                        name={'propertyType'}
                                        component={RadioButton}
                                        label={tl['commercial'] ? tl['commercial'] : 'Commercial'}
                                        radioValue={'Commercial'}
                                        defaultChecked={false}
                                        onChange={(val) => this.handleChange('propertyType', val)}
                                    />
                                </div>
                                {this.state.propertyTypeError ?
                                    <span className="form__form-group-error">
                                        {this.state.propertyTypeError ?
                                            tl[this.state.propertyTypeError] ? tl[this.state.propertyTypeError] : 'This field is required'
                                            : ''
                                        }
                                    </span>
                                    : null
                                }
                            </div>
                            <div className="form__form-group col-md-6">
                                <div className="form__form-group-field row mx-0">
                                    <div className='col-12 col-sm-8 col-md-6 pl-0'>
                                        <p className="form__form-group-label">
                                            {tl['city'] ? tl['city'] : 'City'} *
                                                    </p>
                                        <Field
                                            name='city'
                                            component={DefaultInput}
                                            placeholder={tl['city'] ? tl['city'] : 'City'}
                                            validate={required}
                                        />
                                    </div>
                                    <div className='col-12 col-sm-8 col-md-6 pl-0'>
                                        <p className="form__form-group-label">
                                            {tl['locality'] ? tl['locality'] : 'Locality'} *
                                                    </p>
                                        <Field
                                            name='locality'
                                            component={DefaultInput}
                                            placeholder={tl['locality'] ? tl['locality'] : 'Locality'}
                                            validate={required}
                                        />
                                    </div>
                                    <div className='col-12 col-sm-8 col-md-6 pl-0'>
                                        <p className="form__form-group-label">
                                            {tl['landMark'] ? tl['landMark'] : 'Land Mark'}
                                        </p>
                                        <Field
                                            name='landMark'
                                            component={DefaultInput}
                                            placeholder={tl['landMark'] ? tl['landMark'] : 'Land Mark'}
                                        />
                                    </div>
                                </div>
                            </div>
                            {(this.state.propertyFor && this.state.propertyFor === 'Rent') ||
                                (!this.state.propertyFor && rowData && rowData.propertyFor === 'Rent') ?
                                <div className="form__form-group col-md-6">
                                    <div className="form__form-group-field row mx-0">
                                        <div className='col-12 col-sm-8 col-md-6 pl-0'>
                                            <p className="form__form-group-label">
                                                {tl['rent'] ? tl['rent'] : 'Rent'} *
                                                        </p>
                                            <Field
                                                name='rent'
                                                component={DefaultInput}
                                                placeholder={tl['rent'] ? tl['rent'] : 'Rent'}
                                                validate={required}
                                            />
                                        </div>
                                        <div className='col-12 col-sm-8 col-md-6 pl-0'>
                                            <p className="form__form-group-label">
                                                {tl['deposit'] ? tl['deposit'] : 'Deposit'} *
                                                        </p>
                                            <Field
                                                name='deposit'
                                                component={DefaultInput}
                                                placeholder={tl['deposit'] ? tl['deposit'] : 'Deposit'}
                                                validate={required}
                                            />
                                        </div>
                                        <div className='col-12 col-sm-8 col-md-6 pl-0'>
                                            <p className="form__form-group-label">
                                                {tl['monthlyMaintenance'] ? tl['monthlyMaintenance'] : 'Monthly Maintenance'}
                                            </p>
                                            <Field
                                                name='monthlyMaintenance'
                                                component={DefaultInput}
                                                placeholder={tl['monthlyMaintenance'] ? tl['monthlyMaintenance'] : 'Monthly Maintenance'}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="form__form-group col-md-6">
                                    <div className="col-12 col-sm-8 col-md-6 pl-0">
                                        <span className="form__form-group-label">
                                            {tl['price'] ? tl['price'] : 'Price'} *
                                                    </span>
                                        <div className="form__form-group-field">
                                            <Field
                                                displayPrice={true}
                                                name='totalPrice'
                                                component={DefaultInput}
                                                placeholder={tl['price'] ? tl['price'] : 'Price'}
                                                validate={required}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {(this.state.propertyType && this.state.propertyType !== 'Commercial') ||
                                (!this.state.propertyType && rowData && rowData.propertyType !== 'Commercial') ?
                                <div className="form__form-group col-md-6">
                                    <div className="form__form-group-field row mx-0">
                                        <div className='col pl-0'>
                                            <p className="form__form-group-label">
                                                {tl['builtUpArea'] ? tl['builtUpArea'] : 'Built-Up Area'} *
                                                        </p>
                                            <Field
                                                name='builtUpArea'
                                                component={DefaultInput}
                                                placeholder={tl['builtUpArea'] ? tl['builtUpArea'] : 'Built-Up Area'}
                                                validate={required}
                                            />
                                        </div>
                                        <div className='col pl-0'>
                                            <p className="form__form-group-label">
                                                {tl['carpetArea'] ? tl['carpetArea'] : 'Carpet Area'} *
                                                        </p>
                                            <Field
                                                name='carpetArea'
                                                component={DefaultInput}
                                                placeholder={tl['carpetArea'] ? tl['carpetArea'] : 'Carpet Area'}
                                                validate={required}
                                            />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="form__form-group col-md-6">
                                    <div className="col-12 col-sm-8 col-md-6 pl-0">
                                        <span className="form__form-group-label">
                                            {tl['plotArea'] ? tl['plotArea'] : 'Plot Area'} *
                                                    </span>
                                        <div className="form__form-group-field">
                                            <Field
                                                name='plotArea'
                                                component={DefaultInput}
                                                placeholder={tl['plotArea'] ? tl['plotArea'] : 'Plot Area'}
                                                validate={required}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className="form__form-group col-md-6">
                                <div className="col-12 col-sm-8 col-md-6 pl-0">
                                    <span className="form__form-group-label">
                                        {tl['facing'] ? tl['facing'] : 'Facing'} *
                                                </span>
                                    <div className="form__form-group-field mb-1">
                                        <Field
                                            name={'facing'}
                                            component={Select}
                                            options={[
                                                { label: tl['north'] ? tl['north'] : 'North', value: 'North' },
                                                { label: tl['east'] ? tl['east'] : 'East', value: 'East' },
                                                { label: tl['west'] ? tl['west'] : 'West', value: 'West' },
                                                { label: tl['south'] ? tl['south'] : 'South', value: 'South' },
                                                { label: tl['northEast'] ? tl['northEast'] : 'North East', value: 'North East' },
                                                { label: tl['northWest'] ? tl['northWest'] : 'North West', value: 'North West' },
                                                { label: tl['southEast'] ? tl['southEast'] : 'South East', value: 'South East' },
                                                { label: tl['southWest'] ? tl['southWest'] : 'South West', value: 'South West' },
                                            ]}
                                            placeholder={tl['select'] ? tl['select'] : 'Select'}
                                            onChange={(e) => this.handleChange('facing', e)}
                                        />
                                    </div>
                                    {this.state.facingError ?
                                        <span className="form__form-group-error mt-1">
                                            {this.state.facingError ?
                                                tl[this.state.facingError] ? tl[this.state.facingError] : 'This field is required'
                                                : ''
                                            }
                                        </span>
                                        : null
                                    }
                                </div>
                            </div>
                            <div className="form__form-group col-md-6">
                                <div className="form__form-group-field row mx-0">
                                    <div className='col-12 col-sm-8 col-md-6 pl-0'>
                                        <p className="form__form-group-label">
                                            {tl['noOfFloors'] ? tl['noOfFloors'] : 'Number Of Floors'} *
                                                    </p>
                                        <Field
                                            name='noOfFloors'
                                            component={DefaultInput}
                                            placeholder={tl['noOfFloors'] ? tl['noOfFloors'] : 'Number Of Floors'}
                                            validate={required}
                                        />
                                    </div>
                                    <div className='col-12 col-sm-8 col-md-6 pl-0'>
                                        <p className="form__form-group-label">
                                            {tl['floorNumber'] ? tl['floorNumber'] : 'Floor Number'}
                                        </p>
                                        <Field
                                            name='floorNumber'
                                            component={DefaultInput}
                                            placeholder={tl['floorNumber'] ? tl['floorNumber'] : 'Floor Number'}
                                        />
                                    </div>
                                </div>
                            </div>

                            {
                                (this.state.propertyType && this.state.propertyType !== 'Commercial' && this.state.propertyType !== '1RK') ||
                                    (!this.state.propertyType && rowData && rowData.propertyType !== 'Commercial' && rowData.propertyType !== '1RK') ?
                                    <div className="form__form-group col-md-6">
                                        <span className="form__form-group-label">
                                            {tl['bedRooms'] ? tl['bedRooms'] : 'Bedrooms'} *
                                                    </span>
                                        <div className="form__form-group-field row mx-0">
                                            <Field
                                                className='col pl-0'
                                                name={'bedRooms'}
                                                component={RadioButton}
                                                label={'1'}
                                                radioValue={'1'}
                                                defaultChecked={false}
                                                onChange={(val) => this.handleChange('bedRooms', val)}
                                            />
                                            <Field
                                                className='col pl-0'
                                                name={'bedRooms'}
                                                component={RadioButton}
                                                label={'2'}
                                                radioValue={'2'}
                                                defaultChecked={false}
                                                onChange={(val) => this.handleChange('bedRooms', val)}
                                            />
                                            <Field
                                                className='col pl-0'
                                                name={'bedRooms'}
                                                component={RadioButton}
                                                label={'3'}
                                                radioValue={'3'}
                                                defaultChecked={false}
                                                onChange={(val) => this.handleChange('bedRooms', val)}
                                            />
                                            <Field
                                                className='col pl-0'
                                                name={'bedRooms'}
                                                component={RadioButton}
                                                label={'4'}
                                                radioValue={'4'}
                                                defaultChecked={false}
                                                onChange={(val) => this.handleChange('bedRooms', val)}
                                            />
                                        </div>
                                        {this.state.bedRoomsError ?
                                            <span className="form__form-group-error">
                                                {this.state.bedRoomsError ?
                                                    tl[this.state.bedRoomsError] ? tl[this.state.bedRoomsError] : 'This field is required'
                                                    : ''
                                                }
                                            </span>
                                            : null
                                        }
                                    </div>
                                    : null
                            }
                            {
                                (this.state.propertyType && this.state.propertyType !== 'Commercial' && this.state.propertyType !== '1RK') ||
                                    (!this.state.propertyType && rowData && rowData.propertyType !== 'Commercial' && rowData.propertyType !== '1RK') ?
                                    <div className="form__form-group col-md-6">
                                        <span className="form__form-group-label">
                                            {tl['bathRooms'] ? tl['bathRooms'] : 'Bathrooms'}
                                        </span>
                                        <div className="form__form-group-field row mx-0">
                                            <Field
                                                className='col pl-0'
                                                name={'bathRooms'}
                                                component={RadioButton}
                                                label={'1'}
                                                radioValue={'1'}
                                                defaultChecked={false}
                                                onChange={(val) => this.handleChange('bathRooms', val)}
                                            />
                                            <Field
                                                className='col pl-0'
                                                name={'bathRooms'}
                                                component={RadioButton}
                                                label={'2'}
                                                radioValue={'2'}
                                                defaultChecked={false}
                                                onChange={(val) => this.handleChange('bathRooms', val)}
                                            />
                                            <Field
                                                className='col pl-0'
                                                name={'bathRooms'}
                                                component={RadioButton}
                                                label={'3'}
                                                radioValue={'3'}
                                                defaultChecked={false}
                                                onChange={(val) => this.handleChange('bathRooms', val)}
                                            />
                                            <Field
                                                className='col pl-0'
                                                name={'bathRooms'}
                                                component={RadioButton}
                                                label={'4'}
                                                radioValue={'4'}
                                                defaultChecked={false}
                                                onChange={(val) => this.handleChange('bathRooms', val)}
                                            />
                                        </div>
                                    </div>
                                    : null
                            }
                            <div className="form__form-group col-md-6">
                                <span className="form__form-group-label">
                                    {tl['availability'] ? tl['availability'] : 'Availability'} *
                                            </span>
                                <div className="form__form-group-field row mx-0">
                                    <Field
                                        className='col pl-0'
                                        name={'availability'}
                                        component={RadioButton}
                                        label={tl['underContruction'] ? tl['underContruction'] : 'Under-Construction'}
                                        radioValue={'Under Construction'}
                                        defaultChecked={false}
                                        onChange={(val) => this.handleChange('availability', val)}
                                    />
                                    <Field
                                        className='col pl-0'
                                        name={'availability'}
                                        component={RadioButton}
                                        label={tl['readyToMove'] ? tl['readyToMove'] : 'Ready to Move'}
                                        radioValue={'Ready to Move'}
                                        defaultChecked={false}
                                        onChange={(val) => this.handleChange('availability', val)}
                                    />
                                </div>
                                {this.state.availabilityError ?
                                    <span className="form__form-group-error">
                                        {this.state.availabilityError ?
                                            tl[this.state.availabilityError] ? tl[this.state.availabilityError] : 'This field is required'
                                            : ''
                                        }
                                    </span>
                                    : null
                                }
                            </div>
                            <div className="form__form-group col-md-6">
                                <span className="form__form-group-label">
                                    {tl['furnishingType'] ? tl['furnishingType'] : 'Furnishing Type'} *
                                            </span>
                                <div className="form__form-group-field row mx-0">
                                    <Field
                                        className='col pl-0'
                                        name={'furnishingType'}
                                        component={RadioButton}
                                        label={tl['unFurnished'] ? tl['unFurnished'] : 'Un-Furnished'}
                                        radioValue={'Un-Furnished'}
                                        defaultChecked={false}
                                        onChange={(val) => this.handleChange('furnishingType', val)}
                                    />
                                    <Field
                                        className='col pl-0'
                                        name={'furnishingType'}
                                        component={RadioButton}
                                        label={tl['semiFurnished'] ? tl['semiFurnished'] : 'Semi-Furnished'}
                                        radioValue={'Semi-Furnished'}
                                        defaultChecked={false}
                                        onChange={(val) => this.handleChange('furnishingType', val)}
                                    />
                                    <Field
                                        className='col pl-0'
                                        name={'furnishingType'}
                                        component={RadioButton}
                                        label={tl['furnished'] ? tl['furnished'] : 'Furnished'}
                                        radioValue={'Furnished'}
                                        defaultChecked={false}
                                        onChange={(val) => this.handleChange('furnishingType', val)}
                                    />
                                </div>
                                {this.state.furnishingTypeError ?
                                    <span className="form__form-group-error">
                                        {this.state.furnishingTypeError ?
                                            tl[this.state.furnishingTypeError] ? tl[this.state.furnishingTypeError] : 'This field is required'
                                            : ''
                                        }
                                    </span>
                                    : null
                                }
                            </div>
                            <div className="form__form-group col-md-6">
                                <div className='col pl-0'>
                                    <span className="form__form-group-label">
                                        {tl['amenities'] ? tl['amenities'] : 'Amenities'}
                                    </span>
                                    <div className="form__form-group-field">
                                        <Field
                                            className='textAreaPh'
                                            name='amenities'
                                            component={DefaultTextArea}
                                            placeholder={tl['amenities'] ? tl['amenities'] : 'Amenities'}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form__form-group col-md-6">
                                <div className='col pl-0'>
                                    <span className="form__form-group-label">
                                        {tl['propertyDescription'] ? tl['propertyDescription'] : 'Property Description'}
                                    </span>
                                    <div className="form__form-group-field">
                                        <Field
                                            className='textAreaPh'
                                            name='propertyDescription'
                                            component={DefaultTextArea}
                                            placeholder={tl['propertyDescription'] ? tl['propertyDescription'] : 'Property Description'}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form__form-group col-md-6">
                                <div className='d-flex'>
                                    <div className="">
                                        {tl['browsePhotos'] ? tl['browsePhotos'] : 'Browse Photos'}
                                    </div>
                                    <span
                                        className={this.state.currentPhotoFiles && this.state.currentPhotoFiles.length && this.state.currentPhotoFiles.length > 0 ? 'ml-auto' : 'd-none'}
                                        onClick={this.removeAllSelectedPhotos}
                                        style={{ color: config.themeColor, cursor: 'pointer' }}
                                    >
                                        {tl['removeAll'] ? tl['removeAll'] : 'Remove All'}
                                    </span>
                                </div>
                                <div className='form__form-group-field'>
                                    <Field
                                        onRef={(ref) => (this.DropZoneRef = ref)}
                                        className='addPropDropZone'
                                        name='newPhotos'
                                        component={DropZone}
                                        onChange={this.handleCurrentSelectedPhotos}
                                    />
                                </div>
                            </div>
                            {rowData && rowData.photo && rowData.photo.length > 0 ?
                                <div className="form__form-group col-md-6">
                                    <span className="form__form-group-label">
                                        {tl['photos'] ? tl['photos'] : 'Photos'}
                                    </span>
                                    <div
                                        className='col'
                                        style={{ padding: '20px' }}
                                    >
                                        <div
                                            className='row'
                                            style={{ justifyContent: 'end' }}
                                        >
                                            {rowData && rowData.photo && rowData.photo.length > 0 ?
                                                rowData.photo.map((item, index) => {
                                                    if (item && item.name) {
                                                        return (
                                                            <div
                                                                className='col-sm-3 col-md-4 px-0 pr-1 mb-2'
                                                                key={index}
                                                            >
                                                                <img
                                                                    src={`${config.imgUrl}property/${item.name}`}
                                                                    className='detailsImgStyle'
                                                                    alt=''
                                                                />
                                                                <CloseIcon
                                                                    className='editPropRemovePicIcon'
                                                                    onClick={() => this.removePhotoFromProp(rowData, item)}
                                                                />
                                                            </div>
                                                        )
                                                    } else {
                                                        return null
                                                    }
                                                })
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                            {/* Add or Update Button */}
                            <div className="col-12 text-right">
                                <Button
                                    type='submit'
                                    className="mr-3"
                                    color="primary"
                                >
                                    {formType && formType === 'edit' ?
                                        tl['update'] ? tl['update'] : 'Update'
                                        :
                                        tl['submit'] ? tl['submit'] : 'Submit'
                                    }
                                </Button>
                            </div>
                        </form>
                    </Row >

                    {/* Redirect to #My_Properties after successfull creation of new property */}
                    {this.state.isPropertyCreationSuccess ?
                        <Redirect
                            to={{
                                pathname: '/my_properties',
                                state: {
                                    pageLoad: true,
                                    tl: tl,
                                }
                            }}
                        /> :
                        null
                    }

                    {/* Redirect to #My_Properties on back click */}
                    {this.state.redirectToMyProperties ?
                        <Redirect to={{
                            pathname: this.props.location.state.fromScreen,
                            state: {
                                prevCriteria: this.props.location.state.fromCriteria,
                                prevData: this.props.location.state.fromData,
                                prevScreen: this.props.location.state.fromScreen,
                                ClientIdForMatcheProps: this.props.location.state.ClientIdForMatcheProps,
                                prevFilterCriteria: this.props.location.state.currentFilterCriteria,
                            }
                        }}
                        /> :
                        null
                    }

                    {/* #Session Expire Modal */}
                    {this.state.isOpenSessionExpiredModal ?
                        <SessionExpireModal
                            isOpenSessionExpiredModal={this.state.isOpenSessionExpiredModal}
                            tl={tl}
                        />
                        : null
                    }

                    {this.state.isOpenOtpVerifyModal ?
                        <OtpVerifyModal
                            isOpenOtpVerifyModal={this.state.isOpenOtpVerifyModal}
                            closeOtpVerifyModal={this.closeOtpVerifyModal}
                            tl={tl}
                        />
                        : null
                    }
                </ScrollBar>
            </div >
        );
    }
}

AddPropertiesScreen = reduxForm({
    form: 'addPropertyForm', // a unique identifier for this form
    validate,
    enableReinitialize: true,
})(AddPropertiesScreen);

AddPropertiesScreen = connect(
    state => ({
        initialValues: state.commonData.data // pull initial values from account reducer
    }),
    { load: loadAccount } // bind account loading action creator
)(AddPropertiesScreen);

export default AddPropertiesScreen;


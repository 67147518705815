import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { Modal, ModalBody, Button } from 'reactstrap';
import socket from '../../../config/Socket';

export default class TopbarMenuLinks extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLogoutSuccess: false
    };
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  };

  // Open Logout confirmation modal
  logout = async () => {
    await this.props.toggle();
    this.setState({
      isOpenDeleteModal: true
    })
  }

  // Logout confirmation handler
  navigateToLogin = async () => {
    socket.disconnect();
    await localStorage.removeItem('loginCredentials');
    await localStorage.removeItem('loginBody');
    await localStorage.removeItem('favProperties');
    await this.setState({
      isOpenDeleteModal: false,
      isLogoutSuccess: true,
    })
  }

  // close delete modal
  handleModalBox = () => {
    this.setState({
      isOpenDeleteModal: false
    })
  }

  render() {
    const { title, icon, path, toggle, className, tl } = this.props;

    return (
      <div className={className ? className : ''}>
        {title === 'logout' ?
          <div className="topbar__link" style={{ paddingBottom: '10px', paddingLeft: '23px', paddingTop: '10px' }} onClick={() => this.logout()}>
            <span className={`topbar__link-icon lnr lnr-${icon}`} />
            <span className="topbar__link-title textCapitalize">
              {tl[title] ? tl[title] : title}
            </span>
          </div>

          : title === 'settings' ?
            <Link
              className="topbar__link"
              to={{
                pathname: path,
                state: {
                  tl: tl,
                }
              }}
              onClick={toggle}
            >
              <span className={`topbar__link-icon lnr lnr-${icon}`} />
              <p className="topbar__link-title textCapitalize">
                {tl[title] ? tl[title] : title}
              </p>
            </Link>

            :
            <Link className="topbar__link" to={path} onClick={toggle}>
              <span className={`topbar__link-icon lnr lnr-${icon}`} />
              <p className="topbar__link-title textCapitalize">
                {tl[title] ? tl[title] : title}
              </p>
            </Link>
        }

        {/* Redirect component to Login Screen */}
        {this.state.isLogoutSuccess ?
          <Redirect
            to={{
              pathname: '/',
              state: {
                tl: tl,
              }
            }}
          />
          : null
        }

        {/* Logout confirmation modal */}
        <Modal
          isOpen={this.state.isOpenDeleteModal}
          className={`modal-dialog invalidFilterModal modal-dialog-centered modal-dialog--primary formModalBorderRadius`}
          toggle={() => this.handleModalBox()}
        >
          <ModalBody>
            <h4 className='mb-3'>
              {tl['logoutConfirmation'] ? tl['logoutConfirmation'] : 'Are you sure you want to Logout'}{' ? '}
            </h4>
            <Button
              color="primary"
              onClick={this.handleModalBox}
              className='mb-0'
            >
              {tl['no'] ? tl['no'] : 'No'}
            </Button>
            <Button
              color="primary"
              onClick={this.navigateToLogin}
              className='mb-0'
            >
              {tl['yes'] ? tl['yes'] : 'Yes'}
            </Button>
          </ModalBody>
        </Modal>
      </div>

    );
  }
}
